import React, { useState } from 'react';
import moment from 'moment';
import Functions from '../../../../../../../../Helpers/Functions';
import { Dialog, FormControlLabel } from "@mui/material";
import RadioButtonDashboard from '../../../../UtilsDashboard/RadioButton';
import StepperStore from '../../../../../../../../LocalForage/StepperStore';
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutlineTwoTone";
import DeleteIcon from "@mui/icons-material/DeleteTwoTone";
// import closeCircle from '../../../../../../../Assets/Icons/close_circle.svg'
import Swal from 'sweetalert2';
import PunctualCourseRepository from '../../../../../../../../Repositories/PunctualCourseRepository';
import { LightTooltip, showStatus, capitalizeFirstLetter } from '../../../../UtilsDashboard/FunctionsDashbord';
import loader from "../../../../../../../../Assets/Icons/loader-bleu.gif";
// import TeachrRepository from '../../../../../../../../Repositories/TeachrRepository';
// import ChildRepository from '../../../../../../../../Repositories/ChildRepository';
// import CustomFeesRepository from '../../../../../../../../Repositories/CustomFeesRepository';
// import { capitalizeFirstLetter } from "../../UtilsDashboard/FunctionsDashbord";


const ComponentBodyTablePunctualCourse = (props: any) => {
  let timerInterval: any;
  const { punctual, handleLoad, isHere, isEdit, isDelete, handleEdit, handleLockDelete, checked } = props
  const formattedTime = moment(punctual.startDatetime).format('HH:mm')
  const modifiedTime = moment(formattedTime, 'HH:mm');
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const handleEditCourse = () => {
    StepperStore.setItem("course_id", punctual?.id);
    handleEdit()
  }

  // const [customFees, setCustomFees] = React.useState<any>();
  // const priceCustomFees = !customFees?.is_teachr_contact ? (((punctual?.teachrPrice) * punctual?.duration) / 2).toFixed(2) : (((punctual?.teachrPrice + punctual?.fees) * punctual?.duration) / 2).toFixed(2)

  // React.useEffect(() => {
  //   TeachrRepository.getCurrentTeachr().then((teachrs: any) => {
  //     ChildRepository.fetchChildById(punctual?.forChild_id).then((res: any) => {
  //       const idClient = parseInt(res?.parent["@id"].substr(9));
  //       if (idClient) {
  //         CustomFeesRepository.getCustomFeesSingle(idClient, teachrs.id).then(
  //           (customFees) => {
  //             if (customFees) {
  //               setCustomFees(customFees[0]);
  //               // PunctualCourseRepository.downloadInvoice(
  //               //   punctual?.charge_id
  //               // ).then((response) => {
  //               //   // if (customFees[0].is_teachr_contact) {
  //               //     setDownloadLinkInvoice(response?.invoice);
  //               //   // } else {
  //               //     setDownloadLinkInvoiceRecap(response?.invoiceRecap);
  //               //   // }
  //               // });
  //             }
  //           }
  //         );
  //       }
  //     });
  //   });
  // }, [punctual?.forChild_id]);

  const compoonentDelete = () => {
    return (
      <div className="">
        <Dialog open={load} sx={{ backgroundColor: "transparent" }}>
          <div className="flex items-center justify-center /h-screen bg-transparent">
            <div className="w-1/3 lg:w-max bg-transparent">
              <img src={loader} alt="chargement bg-transparent" />
              <p className="py-8 text-center bg-transparent text-first">
                Chargement ...
              </p>
            </div>
          </div>
        </Dialog>
      </div>
    );
  };
  const displayeDelete = () => {
    return (
      <>
        {Swal.fire({
          title: "Êtes-vous sûr ?",
          text: 'En appuyant sur le bouton "Oui, supprimer!" , vous confirmez la suppression du cours.',
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#4d4d4d",
          confirmButtonText: "Oui, supprimer !",
          cancelButtonText: "Annuler",
          customClass: {
            actions: "my-actions",
            cancelButton: "order-1 right-gap",
            confirmButton: "order-2",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            setLoad(true);
            compoonentDelete();
            handleLoad(true);
            PunctualCourseRepository.deletePunctualByTeachrDashboard(
              punctual?.id
            )
              .then((res) => {
                handleLoad(true);
                setOpen(false);
                setLoad(false);
                Swal.fire({
                  icon: "success",
                  title: "Cours supprimé",
                  text: "  Votre cours a bien été supprimé. Il ne sera pas déclaré et a disparu de votre liste de cours prévus.",
                  timer: 5000,
                  willClose: () => {
                    clearInterval(timerInterval);
                  },
                });
                handleLockDelete();
              })
              .catch((err: any) => {
                handleLoad(false);
                console.warn(err);
                setLoad(false);
              });
          } else {
            setOpen(false);
          }
        })}
      </>
    );
  };
  return (
    <>
      <tr className='b/order-2 group/item hover:bg-slate-100 hover:rounded-xl hover:py-4'>
        
        {isHere && (
          <>
            {isEdit ? (
              <td className="px-4  ">
                <LightTooltip title="Modifier">
                  <FormControlLabel
                    value={punctual?.id}
                    key={punctual?.id}
                    onClick={handleEditCourse}
                    control={
                      <RadioButtonDashboard
                        image={
                          <ModeEditOutlineIcon sx={{ color: "#0054A3" }} />
                        }
                      />
                    }
                    labelPlacement="start"
                    label=""
                  />
                </LightTooltip>
              </td>
            ) : isDelete ? (
              <>
                <td className="px-4  ">
                  <LightTooltip title="Supprimer">
                    <FormControlLabel
                      value={punctual?.id}
                      key={punctual?.id}
                      onClick={displayeDelete}
                      control={
                        <RadioButtonDashboard
                          image={<DeleteIcon sx={{ color: "#0054A3" }} />}
                        />
                      }
                      labelPlacement="start"
                      label=""
                    />
                  </LightTooltip>
                </td>
              </>
            ) : (
              ""
            )}
          </>
        )}
        <td className="px-2 md:py-3  whitespace-no-wrap  text-first border-gray-500 text-sm leading-5 md:flex hidden ">
          {punctual?.isPunctualMonth
            ? "Du " + moment(punctual.startDatetime).format("DD MMM YYYY") + " au " + moment(punctual.endDatetime).format("DD MMM YYYY")
            : "Le " + moment(punctual.startDatetime).format("DD MMM YYYY") + " à " + modifiedTime.format("HH:mm")}

        </td>
        <td className="px-2 py-3 whitespace-no-wrap  border-gray-500 md:hidden">
          <div className="text-sm leading-5 text-first">
            {punctual?.isPunctualMonth
              ? moment(punctual.startDatetime).format("DD/MM/YYYY") + " au " + moment(punctual.endDatetime).format("DD/MM/YYYY")
              : "Le " + moment(punctual.startDatetime).format("DD/MM/YYYY") + " à " + modifiedTime.format("HH:mm")}
          </div>
        </td>
        <td className="px-4 md:py-3 whitespace-no-wrap  border-gray-500 text-first text-sm leading-5">
          {Functions.renderDuration(punctual.duration)}
        </td>
        <td className="px-2 md:py-3 whitespace-no-wrap  text-first border-gray-500 text-sm leading-5">
          {capitalizeFirstLetter(punctual?.forChildFirstName) + " " + capitalizeFirstLetter(punctual?.forChildLastName)}
        </td>
        <td className="px-2 md:py-3 whitespace-no-wrap  border-gray-500 text-first text-sm leading-5">
          {punctual?.subjectAsked}
        </td>
        <td className="px-2 md:py-3 whitespace-no-wrap  border-gray-500 text-first text-sm leading-5">
          {/* {punctual?.chapter === "punctual_month" */}
          {punctual?.isPunctualMonth
            ? "Cours du mois"
            : "Cours ponctuel"}
        </td>
        <td className="px-2 md:py-3 whitespace-no-wrap  border-gray-500 text-first text-center text-sm leading-5">
          {checked ? punctual?.priceCf?.priceCustomFees : punctual?.priceCf?.priceNoFees}€
        </td>
        <td className="px-2 md:py-3 whitespace-no-wrap  text-first border-gray-500 text-sm leading-5">
          {showStatus(punctual)}
        </td>
      </tr>
    </>
  );
};

export default ComponentBodyTablePunctualCourse;