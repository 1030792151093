
import { useNavigate } from 'react-router';
import NavbarHomePage from '../Navbar/NavbarHomePage';
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoArrayHome1 from '../utils/InfoArrayHome1';
import InfoArrayHome2 from '../utils/InfoArrayHome2';
import InfoArrayHome3 from '../utils/InfoArrayHome3';
import imgControl from "../../Assets/Images/controle-de-qualite.png"
import imgTirelire from "../../Assets/Images/tirelire.png"
import imgCasser from "../../Assets/Images/casser.png"
import { Helmet } from "react-helmet";
import schemaTeachr from "../../Assets/Images/SchemaFacturation/SchemaTeachr.png";
import schemaAINoCI from "../../Assets/Images/SchemaFacturation/SchemaAeNoCi.png";
import ImageModal from '../utils/ModalImage';

export default function AeNoCiPageAds() {
  const navigate = useNavigate()
  const dataList = [
    {
      label: "Vos cours sont déclarés",
      system: 1,
      aici: 1,
    },
    {
      label: "Montant de cotisations sociales sur vos cours",
      system: 3,
      aici: 2,
    },
    {
      label: "Remboursement de 50% instantané",
      img: <InfoArrayHome1 />,
      system: 0,
      aici: 1,
    },
    {
      label: "Avoir la main sur vos paiements",
      img: <InfoArrayHome2 />,
      system: 0,
      aici: 1,
    },
    {
      label: "Vous ne gérez ni l'administratif ni la facturation",
      img: <InfoArrayHome3 />,
      system: 0,
      aici: 1,
    },
  ];

  return (
    <>
      <Helmet>
        <meta name="description" content="Auto-etrepreneur crédit d'impôt" />
        {/* tiktok ads */}
        <script>
          {`!function (w, d, t) {
          w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
          var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
          ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
          ttq.load('CPG29N3C77U57258I130');
          ttq.page();
          }(window, document, 'ttq');`}
        </script>

        {/* Meta Pixel Code */}
        <script>{`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '1637785340315682');
          fbq('track', 'PageView');
          `}</script>
        {/* <noscript>
            <img
              height="1"
              width="1"
              style="display:none"
              src="https://www.facebook.com/tr?id=1186395682777492&ev=PageView&noscript=1"
            />
          </noscript> */}
        {/* End Meta Pixel Code */}
      </Helmet>
      <div className="bg-white">
        <NavbarHomePage />
        <div className="bg-home-page  bg-no-repeat bg-cover lg:h-screen md:px-10 px-2 w-full md:mt-24 mt-20">
          <div className="md:w-1/2 md:pt-16 pt-8">
            <h1 className="text-first font-extrabold xl:text-[40px] text-[30px]">
              Professeur particulier ?{" "}
            </h1>
            <p className="text-first font-extrabold  xl:text-[40px] text-[30px]">
              Qu’attendez-vous pour utiliser l’Avance Immédiate ?
            </p>
            <p className="text-first  xl:text-[20px] text-[17px] font-bold mt-6">
              Grâce à Teach’r, permettez dès demain à vos élèves de ne payer que
              la moitié de vos factures !
              <br />
              <br />
              <span
                className="underline cursor-pointer"
                onClick={() => navigate("/teachr/avance-immediate")}
              >
                Je veux une simulation de mes gains !
              </span>
            </p>
            <button
              className="md:w-4/5 w-full mx-auto  border text-white bg-first text-base uppercase rounded-full py-2 font-extrabold shadow-md xl:mt-20 mt-10"
              onClick={() => navigate("/teachr/register")}
            >
              JE M’INSCRIS !
            </button>
          </div>
        </div>
        <div className="w-full z-50 md:px-10 px-4 space-y-4 pb-5">
          <div className="md:flex block md:gap-x-8 mt-2 pb-10 md:space-y-0 space-y-4"></div>
          <div className="pb-10 text-[#4d4d4d] md:text-lg">
            <div className="md:px-10 px-3">
              <div className="w-full mx-auto border-2 rounded-t-3xl shadow-md">
                <table className="w-full">
                  <thead>
                    <tr className="font-bold">
                      <th className="border-b-2 py-4 md:px-4 px-1 text-left w-1/3 ">
                        {/* Quel moyen de paiement pour vos cours ?{" "} */}
                      </th>
                      <th className="border-b-2 border-l-2 border-r-2 py-4 md:px-4 px-1 w-1/3">
                        Auto-entrepreneur sans crédit d'impôt
                      </th>
                      <th className="border-b-2 py-4 md:px-4 px-1 w-1/3 bg-[#EBF7FF] rounded-tr-3xl">
                        Avec notre service, l’avance immédiate pour les
                        professeurs indépendants
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataList.map((item, index) => (
                      <tr key={index}>
                        <td className="border-b-2 flex gap-x-2 py-4 md:px-4 px-1 ">
                          {item?.label} {item.img}
                        </td>
                        <td
                          className={
                            item.system === 1
                              ? "border-b-2 border-l-2 border-r-2 py-4 md:px-4 px-1  text-[#0074E4] text-center"
                              : item.system === 0
                              ? "border-b-2 border-l-2 border-r-2 py-4 md:px-4 px-1  text-[#FF724F] text-center"
                              : "border-b-2 border-l-2 border-r-2 py-4 md:px-4 px-1  text-[#4d4d4d] font-bold text-center"
                          }
                        >
                          {item.system === 1 ? (
                            <CheckCircleIcon />
                          ) : item.system === 0 ? (
                            <CancelSharpIcon />
                          ) : item.system === 2 ? (
                            "45%"
                          ) : (
                            "21,1% ou 10,55% avec l'ACRE"
                          )}
                        </td>
                        <td
                          className={
                            item.aici === 1
                              ? "border-b-2 border-l-2 border-r-2 py-4 md:px-4 px-1  text-[#0074E4] text-center"
                              : item.aici === 2
                              ? "border-b-2 border-l-2 border-r-2 py-4 md:px-4 px-1 font-bold text-[#4d4d4d] text-center"
                              : "border-b-2 border-l-2 border-r-2 py-4 md:px-4 px-1  text-[#FF724F] text-center"
                          }
                        >
                          {item.aici === 1 ? (
                            <CheckCircleIcon />
                          ) : item.aici === 2 ? (
                            "21,1% ou 10,55% avec l'ACRE"
                          ) : (
                            <CancelSharpIcon />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex border-2 md:px-10 rounded-3xl -mt-3 z-50 bg-gradient-to-l from-[#EBF7FF] to-white shadow-lg font-bold">
              <div className="py-8 md:px-4 px-1 w-1/3 bg-white">
                Montant net perçu pour un cours payé 25€/h par votre élève, avec
                / sans crédit d’impôt
              </div>
              <div className="border-l-2 border-r-2 py-8 md:px-4 px-1 w-1/3 bg-white">
                25€ payés par votre élève, 19,7€ net (21,1%) ou 22,35€ net
                (10,55%)
              </div>
              <div className="py-8 md:px-4 px-1 w-1/3 bg-[#EBF7FF] rounded-tr-3xl">
                Coût élève : 25€ après crédit d'impôt instantané.
                <strong className="text-first">
                  {" "}
                  Rémunération 34,51€ net (21,1%) ou 39,13€ (10,55%), déduit des
                  frais de Teach'r de 12,5%
                </strong>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full z-50 lg:px-16 md:px-10 px-4 md:space-y-8 space-y-4 pb-10">
          <div className="md:space-y-10 space-y-4 pb-4">
            <h2 className="font-extrabold xl:text-[36px] text-[26px] text-first text-center">
              Pourquoi choisir Teach’r ?
            </h2>
            <p className="text-[#808080] xl:text-[24px] lg:text-[20px] text-[18px] ">
              Teach'r est l’un des rares services vous offrant un accès à
              l’Avance Immédiate pour vos cours sans aucune démarche
              administrative à réaliser. Nous vous permettons d'accéder à
              l’Avance Immédiate dans des délais imbattables, tout en proposant
              les frais de gestion les plus bas du marché !
            </p>
          </div>
          <div className="md:space-y-10 space-y-4 pt-4">
            <h3 className="font-extrabold xl:text-[36px] text-[26px] text-first text-center">
              Les Avantages de Teach'r :
            </h3>
            <div className="grid md:grid-cols-3 grid-cols-1 gap-4 ">
              <div className="flex items-center text-first gap-x-2">
                <img
                  src={imgTirelire}
                  alt="Réduction des cotisations"
                  className="md:w-[150px] md:h-[150px] w-[80px] h-[80px]"
                />
                <div className="/text-[#808080] xl:text-lg md:text-base text-sm">
                  <span className="text-[#4d4d4d] font-bold">
                    {" "}
                    Remboursement immédiat :
                  </span>
                  <p className="text-[#4d4d4d]">
                    Vos élèves n’auront plus qu’à débourser seulement 50% du
                    montant que vous leur facturez ! C’est une aubaine pour leur
                    pouvoir d’achat, et ça leur permettra de prendre plus de
                    cours qu’avant !
                  </p>
                </div>
              </div>
              <div className="flex items-center text-first gap-x-2">
                <img
                  src={imgCasser}
                  alt="Gestion simplifiée"
                  className="md:w-[150px] md:h-[150px] w-[80px] h-[80px]"
                />
                <div className="/text-[#808080] xl:text-lg md:text-base text-sm ">
                  <span className="text-[#4d4d4d] font-bold">
                    Gestion simplifiée :{" "}
                  </span>
                  <p className="text-[#4d4d4d]">
                    Grâce à l'adresse mail de votre élève, envoyez-lui
                    facilement un formulaire d'inscription. Une fois complété,
                    votre élève n'aura plus rien à faire. Renseignez vos cours
                    en quelques secondes et laissez-nous gérer l'administratif.
                    Concentrez-vous sur l'essentiel : vos cours !
                  </p>
                </div>
              </div>
              <div className="flex items-center text-first gap-x-2">
                <img
                  src={imgControl}
                  alt="Contrôle Total"
                  className="md:w-[150px] md:h-[150px] w-[80px] h-[80px]"
                />
                <div className="/text-[#808080] xl:text-lg md:text-base text-sm ">
                  <span className="text-[#4d4d4d] font-bold">
                    Contrôle Total :{" "}
                  </span>
                  <p className="text-[#4d4d4d]">
                    Vous n’aurez plus à recevoir vos paiements en vrac de la
                    part de chacun de vos élèves. Centralisez vos virements, et
                    ayez la main sur les prélèvements de tous vos élèves ! Nous
                    nous occupons également de l’édition de l’envoi de vos
                    factures, ainsi que les attestations annuelles à fournir à
                    vos élèves.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="md:space-y-10 space-y-4 pt-4 pb-10">
            <h3 className="font-extrabold xl:text-[36px] text-[26px] text-first text-center">
              Découvrez comment fonctionne la facturation avec Teach'r :
            </h3>
            <div className="md:flex md:items-center text-first gap-x-2">
              <div className="md:w-1/2 /xl:text-lg /md:text-base text-sm">
                <p className="text-[#808080] xl:text-[24px] lg:text-[20px] text-[18px] md:leading-10 leading-8 ">
                  Simplifiez votre gestion administrative avec Teach'r. De la
                  création de votre déclaration SAP à la transmission des
                  paiements, en passant par la génération et l'envoi des
                  factures, nous prenons tout en charge. Vous n'avez plus à vous
                  soucier de rien. Découvrez comment Teach'r peut transformer
                  votre quotidien en comparant votre situation actuelle avec
                  notre offre.{" "}
                </p>
              </div>
              <div className="/md:flex md:w-1/2 relative">
                <div className="text-center relative mx-auto">
                  <ImageModal src={schemaAINoCI} alt="Placeholder Image" />
                  <span className="text-center w-full">
                    Votre situation actuelle
                  </span>
                </div>
                <div className="text-center relative mx-auto">
                  <ImageModal src={schemaTeachr} alt="Placeholder Image" />
                  <span>Avec Teach'r</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
