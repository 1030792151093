import React, { useEffect, useState, forwardRef, useImperativeHandle, Dispatch, SetStateAction, } from "react";
import moment from "moment";
import Functions from "../../../../../../Helpers/Functions";
import { BpCheckedIcon, BpIcon2, LightTooltipCours, capitalizeFirstLetter, convertDateTimeFormat, displayDialog, extractDateTimeFromString, validatePrice } from "../../UtilsDashboard/FunctionsDashbord";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutlineTwoTone";
import DeleteIcon from "@mui/icons-material/DeleteTwoTone";
import { Checkbox, FormControlLabel } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { DatePickerValue, EndDatePickerValue, } from "../../UtilsDashboard/DemoDate";
import SubjectsSelects from "../../../../../../Components/utils/SelectMatiere";
import { Subject } from "../../../../../../Objects/models/Subject";
import ChildRepository from "../../../../../../Repositories/ChildRepository";
import CustomFeesRepository from "../../../../../../Repositories/CustomFeesRepository";
import PunctualCourseRepository from "../../../../../../Repositories/PunctualCourseRepository";
import { PunctualCourseDashboard } from "../../../../../../Objects/models/PunctualCourseDashboard";
import { LightTooltipNoError } from "../../UtilsDashboard/FunctionsDashbord";
import SendIcon from "@mui/icons-material/Send";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { APIErrorEnum } from "../../../../../../Objects/enums/APIErrorEnum";
import { useDispatch } from "react-redux";
import CourseProposal from "../../../../../../Objects/models/CourseProposal";
import CourseProposalRepository from "../../../../../../Repositories/CourseProposalRepository";
import Swal from "sweetalert2";
import Teachr from "../../../../../../Objects/models/Teachr";
import { PunctualCourseDashboardUpdate } from "../../../../../../Objects/models/PunctualCourseDashboardUpdate";
import CourseProposalDashboard from "../../../../../../Objects/models/CourseProposalDashbordUpdate";
import StepperStore from "../../../../../../LocalForage/StepperStore";
import { styled } from "@mui/material/styles";
import TeachrRepository from "../../../../../../Repositories/TeachrRepository";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import InputBase from "@mui/material/InputBase";
import AddressRepository from "../../../../../../Repositories/AddressRepository";
import SubjectRepository from "../../../../../../Repositories/SubjectRepository";
import ClientRepository from "../../../../../../Repositories/ClientRepository";
let timerInterval: any
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid gray",
    fontSize: "14px",
    padding: "12px 20px 12px 12px",
    width: "150px",
    fontFamily: "Nunito, 'sans-serif'",
  },
}));
interface DeleteModel {
  id: number;
  isCancelled: boolean
}
interface ErrorState {
  [key: string]: {
    price?: string;
    startDate?: string;
    endDate?: string;
    matiere?: string;
  };
}
interface CourseForAllProps {
  checkedItems: Record<number, { checked: boolean; cours: PunctualCourseDashboard | any }>;
  punctual: PunctualCourseDashboard | any;
  setCheckedItems: Dispatch<SetStateAction<Record<number, { checked: boolean; cours: PunctualCourseDashboard | any }>>>;
  teachr: Teachr | any | undefined;
  setLoad: Dispatch<SetStateAction<boolean>>;
  setCheck: Dispatch<SetStateAction<boolean>>;
  setCheck2: Dispatch<SetStateAction<boolean>>;
  check: boolean;
  check2: boolean;
  errors: ErrorState | any;
  setErrors: Dispatch<React.SetStateAction<ErrorState>>;
  course2: PunctualCourseDashboard | any;
  uid: string | any;
  validateCourse: (course: Course, setErrors: Dispatch<React.SetStateAction<ErrorState>>) => boolean;
  data: Course[];
  dataError: Course[];
  checked: boolean
}
interface CourseForAllHandles {
  handleSubmitAllCourse: (cours: any, isAllCourse: boolean) => void;
}
interface Course {
  forChildFirstName?: string;
  forChildLastName?: string;
  id: number;
  teachrPrice?: number;
  endDatetime?: string | Date;
  startDatetime?: string | Date;
  subjectAskedObject?: any;
  duration?: number | string;
  subjectAsked_id?: number;
  address_id?: number;
  forChild_id?: number;
  allCourse?: boolean;
  isNew?: boolean;
  isPassedCourse?: boolean;
}
const ComponentCourseForAll = forwardRef<CourseForAllHandles, CourseForAllProps>((props, ref) => {
  const { punctual, setCheckedItems, checkedItems, teachr, setLoad, setCheck2, errors, validateCourse, setErrors, course2, data, dataError, uid, checked } = props;
  const styleTd = () => {
    return "px-6 /border-2 md:py-1 whitespace-no-wrap /border-gray-500 text-first text-sm leading-5";
  };
  const cours = punctual?.cours;
  const title = "Déclarer un cours";
  const icon = "warning";
  const confirmText = "Déclarer";
  const cancelText = "Annuler";
  const color = "#0054A3";
  const dispatch = useDispatch();
  const currentMonth = new Date().getMonth();
  const today = new Date();
  const min = today.getMinutes();
  const valeur = 60 - min;
  const newMin = valeur + min;
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

  const endTime = cours?.isNew ? moment(cours?.endDatetime).toDate() : moment(cours?.endDatetime).add(1, "M").toDate();
  const startTime = cours?.isNew ? moment(cours?.startDatetime).toDate() : moment(cours?.startDatetime).add(1, "M").toDate();

  const [endDate, setEndDate] = React.useState<any>(endTime);
  const [startDate, setStartDate] = React.useState<any>(startTime);
  const [durationMonth, setDurationMonth] = React.useState<number>(cours?.duration / 2);

  const [teachrPrice, setTeachrPrice] = useState<any>();
  const [student, setStudent] = React.useState<any>();
  const pricePerHour = !student ? cours?.teachrPrice : student?.price_per_hour;
  const client = !student ? cours?.client : student?.client_id;

  const isTeachrContact = !student ? cours?.isteachrcontact : student?.is_teachr_contact;
  const dispalayPrice = !teachrPrice ?
    (isTeachrContact ?
      (!cours?.isNew ?
        (cours?.teachrPrice?.toString() * durationMonth).toFixed(2)
        : parseFloat(cours?.teachrPrice))
      : (pricePerHour * durationMonth).toFixed(2))
    : (teachrPrice * durationMonth * 2 * (1 - teachr?.defaultFees));
  const priceValidHour = !teachrPrice
    ? isTeachrContact
      ? !cours?.isNew
        ? (cours?.teachrPrice?.toString() / (1 - teachr?.defaultFees) / 2).toFixed(2)
        : parseFloat(cours?.teachrPrice)
      : ""
    : teachrPrice;
  const priceValid = !teachrPrice
    ? isTeachrContact
      ? !cours?.isNew
        ? (cours?.teachrPrice?.toString() / (1 - teachr?.defaultFees) / 2).toFixed(2)
        : parseFloat(cours?.teachrPrice)
      : (parseFloat(pricePerHour)).toFixed(2)
    : teachrPrice;
  const amounts = !teachrPrice
    ? isTeachrContact
      ? !cours?.isNew
        ? cours?.amount
        : parseFloat(cours?.teachrPrice)
      : (pricePerHour * durationMonth).toFixed(2)
    : (teachrPrice * durationMonth * 2).toFixed(2);

  const priceValid2 = !teachrPrice ? (parseFloat(cours?.teachrPrice) * durationMonth).toFixed(2) : teachrPrice;

  const [day, setDay] = useState(0);
  const [givenMonth, setGivenMonth] = React.useState();
  const [edit, setEdit] = React.useState(false);
  const [lock, setLock] = React.useState(false);
  const [matiereInit, setMatiereInit] = useState<any>();
  const [matiere, setMatiere] = useState<any>();
  // const [customFees, setCustomFees] = useState<any>();
  const [clientTiersPrest, setClientTiersPrest] = React.useState<any>();
  // const priceValid = teachrPrice === undefined ? (cours?.teachrPrice?.toString() / (1 - teachr?.defaultFees) / 2).toFixed(2) : teachrPrice;
  const duration = durationMonth === cours?.duration ? cours?.duration : durationMonth * 2;
  const coursDetails: any = {
    // teachrPrice: isTeachrContact && !cours?.isNew ? priceValid : isTeachrContact && cours?.isNew ? priceValid2 : pricePerHour,
    teachrPrice: priceValid,
    startDatetime: startDate,
    endDatetime: endDate,
    subjectAskedObject: !matiere ? matiereInit : matiere,
    duration: (durationMonth * 2).toString(),
    id: cours?.id,
    address_id: cours?.address_id,
    forChild_id: cours?.isNew ? student ? student?.child_id : cours?.forChild_id : cours?.forChild_id,
    subjectAsked_id: !matiere ? cours?.subjectAsked_id : matiere?.id,
    isPassedCourse: cours?.isPassedCourse,
    isNew: cours?.isNew
  };
  const errorFile = {
    child: errors[cours?.id]?.child,
    price: errors[cours?.id]?.price,
    endDate: errors[cours?.id]?.endDate,
    startDate: errors[cours?.id]?.startDate,
    matiere: errors[cours?.id]?.matiere,
  };
  // const loadCustomFees = () => {
  //   if (cours?.forChild_id) {
  //     ChildRepository.fetchChildById(cours?.forChild_id).then((res: any) => {
  //       const idClient = parseInt(res?.parent["@id"].substr(9));
  //       if (idClient) {
  //         CustomFeesRepository.getCustomFeesSingle(idClient, teachr?.id)
  //           .then((response) => {
  //             if (response.length === 0) {
  //               setCustomFees("nothing");
  //             } else {
  //               setCustomFees(response[0]);
  //             }
  //           })
  //           .catch((error) => console.warn(error));
  //       }
  //     }).catch((err) => {
  //       if (err._description === "Invalid Authorization header.") {
  //         // Swal.fire({
  //         //   title: "Erreur",
  //         //   text: "Vous avez passé trop de temps sur cette page sans activité. Merci de rafraîchir la page ou de vous reconnecter pour continuer.",
  //         //   icon: "warning",
  //         //   showCancelButton: false,
  //         //   confirmButtonColor: "#d33",
  //         //   cancelButtonColor: "#4d4d4d",
  //         //   confirmButtonText: "Actualiser",
  //         //   //   cancelButtonText: "Annuler",
  //         //   customClass: {
  //         //     actions: "my-actions",
  //         //     // cancelButton: "order-1 right-gap",
  //         //     confirmButton: "order-2",
  //         //   },
  //         // }).then((result) => {
  //         //   if (result.isConfirmed) {
  //         //     window.location.reload();
  //         //   }
  //         // });
  //       }
  //     });
  //   } else {
  //     if (student) {
  //       CustomFeesRepository.fetchCustomFeesById(student?.custom_fees_id).then((response: any) => {
  //         const feesCustom = {
  //           client_id: parseInt(response?.client[0].substr(9)),
  //           custom_fees_id: response?.id,
  //           fees_per_hour: response?.pricePerHour,
  //           id: response?.id,
  //           is_archive: response?.isArchive,
  //           is_teachr_contact: response?.isTeachrContact,
  //           price_per_hour: response?.pricePerHour,
  //           teachr_id: parseInt(response?.teachr[0].substr(9)),
  //         };
  //         setCustomFees(feesCustom);
  //       })
  //     }
  //   }
  // }
  // useEffect(() => {
  //   loadCustomFees();
  // }, [cours, student]);

  useEffect(() => {
    const dateTime = extractDateTimeFromString(cours?.descriotion);
    const givenDateString = dateTime;
    const givenDate: any = convertDateTimeFormat(givenDateString);
    const givenMonths = givenDate?.getMonth();
    setGivenMonth(givenMonths);
    let today = firstDay?.getTime();
    let dateGiven = givenDate?.getTime();
    let msDay = 24 * 60 * 60 * 1000;
    let days = Math.floor((dateGiven - today) / msDay);
    setDay(days + 2);
  }, []);
  useEffect(() => {
    if (cours?.subjectAsked_id) {
      SubjectRepository.fetchSubjectById(cours?.subjectAsked_id).then((res) => {
        setMatiereInit(res);
      },)
    }
  }, [])
  const getMatiere = (value: Subject[]) => {
    setMatiere(value);
  };
  const createCourseNextMonth = async (courses: any, isAllCourse: boolean) => {
    const durationCourse = (courses.duration).toString();
    const subject = courses?.subjectAsked_id;
    const address = courses?.address_id;
    const childId = courses?.forChild_id;
    const paymentMean = "CARD";
    const isFiscalReductionEnabled = false;
    const chapter = "";
    const teachr_id = teachr?.id;
    const isRemote = false;
    const isCourseInTheHour = false;
    const isDirectCourse = true;
    const globalExtraInformation = "";
    const teachrPrices = teachrPrice === undefined ? (courses?.teachrPrice * 2 * (1 - teachr?.defaultFees)).toString() : (teachrPrice * 2 * (1 - teachr?.defaultFees)).toString();
    let startDatetime = moment(courses.startDatetime).set("hour", today.getHours() + 1).set("minutes", newMin).toDate();
    let endDatetime = moment(courses.endDatetime).set("hour", today.getHours() + 1).set("minutes", newMin).toDate();

    const course = new PunctualCourseDashboard(
      childId,
      chapter,
      address,
      subject,
      startDatetime,
      endDatetime,
      isRemote,
      teachr_id,
      isCourseInTheHour,
      durationCourse,
      isDirectCourse,
      isFiscalReductionEnabled,
      paymentMean,
      true,
      "",
      globalExtraInformation
    );
    course.isPunctualMonth = true;
    course.isPassedCourse = false;
    course.isNew = courses?.isNew

    const statusTDP = await ClientRepository.refreshStatusTdp(client)

    if (statusTDP && statusTDP?.etat === "OK") {
      PunctualCourseRepository.createPunctualCourseByTeacher(course).then((result) => {
        setLoad(true);
        if (teachr["@id"]) {
          const cours: any = `/punctual_courses/${result.id}`;
          const description = null;
          const proposal = new CourseProposal(
            teachr["@id"],
            cours,
            startDatetime,
            endDatetime,
            teachrPrices,
            description
          );
          proposal.isFromTeachr = true;
          CourseProposalRepository.createCourseProposal(proposal).then((res: any) => {
            setLoad(true);
            PunctualCourseRepository.tryAcceptPunctualCourseByTeachr(res?.punctualCourse.forChild.parent.id, res.id).then(async (res) => {
              if (!courses?.isPassedCourse && courses?.id) {
                setLoad(true);
                const coursePassed = { isPassedCourse: true };
                let isDelete = false;
                setLoad(true);
                if (course2.hasOwnProperty(courses?.id)) {
                  if (courses?.isNew) {
                    delete course2[courses?.id];
                    StepperStore.setItem(`course${uid}`, { course: course2 });
                    isDelete = true;
                  } else {
                    const courseDelete: any = await PunctualCourseRepository.setCoursePassed(courses.id, coursePassed);
                    setLoad(true);
                    if (courseDelete?.isPassedCourse && courseDelete?.id) {
                      delete course2[courseDelete?.id];
                      StepperStore.setItem(`course${uid}`, { course: course2 });
                      isDelete = true;
                    } else {
                      setLoad(false);
                      dispatch({
                        type: "SHOW_ERROR",
                        payload: `Impossible de supprimer ce cours, veuillez contacter le service client de Teach'r`,
                      });
                    }
                  }
                } else {
                  setLoad(false);
                  dispatch({
                    type: "SHOW_ERROR",
                    payload: `Aucun élément trouvé avec l'id ${courses?.id}`,
                  });
                }
                // if (courses?.isNew) {
                //   if (course2.hasOwnProperty(courses?.id)) {
                //     setLoad(true);
                //     delete course2[courses?.id];
                //     StepperStore.setItem(`course${uid}`, { course: course2 });
                //   } else {
                //     dispatch({
                //       type: "SHOW_ERROR",
                //       payload: `Aucun élément trouvé avec l'id 11 ${courses?.id}`,
                //     });
                //   }
                // } else {
                //   PunctualCourseRepository.setCoursePassed(courses.id, coursePassed).then((res) => {
                //     if (res?.isPassedCourse && res?.id) {
                //       if (course2.hasOwnProperty(res?.id)) {
                //         setLoad(true);
                //         delete course2[res?.id];
                //         StepperStore.setItem(`course${uid}`, { course: course2 });
                //       } else {
                //         dispatch({
                //           type: "SHOW_ERROR",
                //           payload: `Aucun élément trouvé avec l'id 22 ${cours?.id}`,
                //         });
                //       }
                //     }
                //   }).catch((err) => {
                //     console.warn(err);
                //     setLoad(false);
                //   });
                // }
                if (!isAllCourse) {
                  dispatch({
                    type: "SHOW_SUCCESS",
                    payload: "Votre cours a bien été validé. Il apparait déjà dans votre tableau de bord !",
                  });
                } else {
                  if (data.length === 0 && dataError.length === 0) {
                    setLoad(true);
                    dispatch({
                      type: "SHOW_SUCCESS",
                      payload: "Votre cours a bien été validé. Il apparait déjà dans votre tableau de bord !",
                    });
                  }
                }
              }
            }).catch((err) => {
              console.warn(err);
              setLoad(false);
            });
          }).catch((error) => {
            setLoad(false);
            console.warn(error);
            if (error._description === "The Teach'r has already an accepted course for this timeslot.") {
              dispatch({
                type: "SHOW_WARNING",
                payload: "Vous avez déjà un cours prévu pour ce créneau horaire.",
              });
            }
            if (error._arrayInvalidAttributes[0].description === "This value should be between 9 and 23.") {
              dispatch({
                type: "SHOW_WARNING",
                payload: "Le prix par heure doit se situer dans une fourchette allant de 9 à 23 euros.",
              });
            }
            if (error?._arrayInvalidAttributes[0].description === "This value should be between 11 and 250.") {
              dispatch({
                type: "SHOW_WARNING",
                payload: "Le prix par heure doit se situer dans une fourchette allant de 11 à 250 euros.",
              });
            }
          });
        }
      }).catch((error) => {
        console.warn(error);
        setLoad(false);
        switch (error.description) {
          case APIErrorEnum.PUNCTUAL_COURSE_RANGE_NOT_CORRESPONDING:
            dispatch({
              type: "SHOW_WARNING",
              payload:
                "La durée du cours est supérieure à la plage horaire définie.",
            });
            break;
          case APIErrorEnum.PUNCTUAL_COURSE_CLIENT_MUST_HAVE_AT_LEAST_ONE_ADDRESS:
            dispatch({
              type: "SHOW_WARNING",
              payload:
                "Vous devez définir au moins une adresse dans votre compte.",
            });
            break;
          case APIErrorEnum.COURSE_PROPOSAL_TEACHR_HAS_ALREADY_A_COURSE_AT_THIS_TIME:
            dispatch({
              type: "SHOW_WARNING",
              payload: "Vous avez déjà un cours prévu à cette date",
            });
            break;
          case APIErrorEnum.PUNCTUAL_COURSE_PENDING_RESERVATIONS_LIMIT_REACHED:
            dispatch({
              type: "SHOW_WARNING",
              payload: "Vous avez déjà trop de demandes de cours en attente.",
            });
            break;
          case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MAX:
            dispatch({
              type: "SHOW_WARNING",
              payload: "La durée du cours ne peut pas dépasser les 12 heures.",
            });
            break;
          case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MIN:
            dispatch({
              type: "SHOW_WARNING",
              payload:
                "La durée du cours ne peut pas être inférieure à 1 heure.",
            });
            break;
          case APIErrorEnum.PUNCTUAL_COURSE_IS_NOT_COURSE_IN_THE_HOUR:
            dispatch({
              type: "SHOW_WARNING",
              payload: "Il ne s'agit pas d'un cours dans l'heure.",
            });
            break;
          case APIErrorEnum.PUNCTUAL_COURSE_IS_COURSE_IN_THE_HOUR:
            dispatch({
              type: "SHOW_WARNING",
              payload: "Il s'agit d'un cours dans l'heure.",
            });
            break;
          case APIErrorEnum.PUNCTUAL_COURSE_DIRECT_COURSE_NOT_AUTHORIZED:
            dispatch({
              type: "SHOW_WARNING",
              payload:
                "Vous n'êtes pas autorisé à demander un cours directement à ce Teach'r.",
            });
            break;
          default:
            dispatch({
              type: "SHOW_ERROR",
              payload: "Erreur inconnue",
            });
            break;
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: `Le compte de votre client a été bloqué pour le moment. Vous ne pouvez plus déclarer de cours à cet élève pour l'instant. Vous allez prochaiment recevoir un mail de notre part pour vous expliquer la situation. Merci de votre compréhension.`,
        //  timer: 5000,
        willClose: () => {
          clearInterval(timerInterval);
        },
      });
    }

  };
  const sendingSingleCourse = () => {
    const text = `Vous êtes sur le point de déclarer les cours pour le mois de ${capitalizeFirstLetter(cours?.forChildFirstName)} ${capitalizeFirstLetter(cours?.forChildLastName)}. Veuillez vérifier attentivement toutes les informations relatives aux cours. En cliquant sur "Déclarer", vous procéderez à la validation de cette déclaration.`;
    if (validateCourse(coursDetails, setErrors)) {
      Swal.fire({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: true,
        confirmButtonColor: color,
        cancelButtonColor: "#4d4d4d",
        confirmButtonText: confirmText,
        cancelButtonText: cancelText,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          createCourseNextMonth(coursDetails, false);
        }
      });
    }
  };
  const editCourse = () => {
    if (validateCourse(coursDetails, setErrors)) {

      const teachrPrices = teachrPrice === undefined ? cours?.teachrPrice?.toString() : (teachrPrice * 2 * (1 - teachr?.defaultFees)).toString()
      let startDateTime2 = moment(startDate)
        .subtract(1, "M")
        .set("hour", today.getHours() + 1)
        .set("minutes", newMin)
        .toDate();
      let endDateTime2 = moment(endDate)
        .subtract(1, "M")
        .set("hour", today.getHours() + 1)
        .set("minutes", newMin)
        .toDate();
      const course = new PunctualCourseDashboardUpdate(
        startDateTime2,
        endDateTime2,
        duration.toString()
      );
      PunctualCourseRepository.updatePunctualCourseByTeachr(cours?.id, course)
        .then((res) => {
          setLoad(true);
          const proposals = new CourseProposalDashboard(
            teachrPrices,
            startDateTime2,
            endDateTime2
          );
          if (cours?.acceptedproposal_id) {
            CourseProposalRepository.updateCourseProposalCourseByTeachr(cours?.acceptedproposal_id, proposals).then((res) => {
              PunctualCourseRepository.getCourseNextMonth(teachr?.id).then((response) => {
                setLoad(true);
                const initCourse: any = {}
                Object.values(response).forEach((course: any) => { initCourse[course?.id] = course });
                course2[cours?.id] = initCourse[cours.id];
                StepperStore.setItem(`course${uid}`, { course: course2 });
                handleEdit();

              })
            }).catch((err) => {
              console.warn(err);
              setLoad(false);
            });
          }
        })
        .catch((err) => {
          console.warn(err);
          setLoad(false);
        });
    }
  };
  const handleEdit = () => {
    setEdit(!edit);
  };
  const handleDelete = () => {
    return (
      <>
        {Swal.fire({
          title: "Êtes-vous sûr ?",
          text: 'En appuyant sur le bouton "Oui, supprimer!" , vous confirmez la suppression du cours.',
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#4d4d4d",
          confirmButtonText: "Oui, supprimer !",
          cancelButtonText: "Annuler",
          customClass: {
            actions: "my-actions",
            cancelButton: "order-1 right-gap",
            confirmButton: "order-2",
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            let isDelete = false
            setLoad(true);
            if (course2.hasOwnProperty(cours?.id)) {
              if (cours?.isNew) {
                delete course2[cours?.id];
                StepperStore.setItem(`course${uid}`, { course: course2 });
                isDelete = true
              } else {
                // const courseDelete: DeleteModel | any = await PunctualCourseRepository.deletePunctualByTeachrDashboard(cours?.id)
                const coursePassed = { isPassedCourse: true };
                const courseDelete: any = await PunctualCourseRepository.setCoursePassed(cours?.id, coursePassed);
                setLoad(true);
                if (courseDelete?.isPassedCourse) {
                  delete course2[courseDelete?.id];
                  StepperStore.setItem(`course${uid}`, { course: course2 });
                  isDelete = true;
                } else {
                  setLoad(false);
                  dispatch({
                    type: "SHOW_ERROR",
                    payload: `Impossible de supprimer ce cours, veuillez contacter le service client de Teach'r`,
                  });
                }
              }
            } else {
              setLoad(false);
              dispatch({
                type: "SHOW_ERROR",
                payload: `Aucun élément trouvé avec l'id ${cours?.id}`,
              });
            }

            if (isDelete) {
              dispatch({
                type: "SHOW_SUCCESS",
                payload:
                  "Votre cours a bien été supprimé. Il ne sera pas déclaré et a disparu de votre liste de cours.",
              });
            }
          }
        })}
      </>
    );
  };
  const handleAddDuration = () => {
    if (durationMonth <= 49.5) {
      setDurationMonth((currentValue: number) => currentValue + 0.25);
    }
  };
  const handleSubtractDuration = () => {
    if (durationMonth >= 0.5) {
      setDurationMonth((currentValue: number) => currentValue - 0.25);
    }
  };
  const getStartDate = (value: any) => {
    setStartDate(value);
  };
  const getEndDate = (value: any) => {
    setEndDate(value);
  };
  const handleSingleCheck = (checked: boolean) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [cours?.id]: { ...prevState[cours?.id], checked: checked },
    }));
    setCheck2(true)
  };
  useImperativeHandle(ref, () => ({
    handleSubmitAllCourse(courses, isAllCourse) {
      createCourseNextMonth(courses, isAllCourse);
    },
  }));
  useEffect(() => {
    TeachrRepository.get_client_by_teacher_favorite(teachr.id).then(
      (client) => {
        setClientTiersPrest(client.clients);
      }
    );
  }, []);
  const handleChange = (event: any) => {
    setStudent(event.target.value);
  };
  const buttonDelete = () => {
    return (
      <LightTooltipCours title="Supprimer">
        <DeleteIcon
          onClick={handleDelete}
          sx={{ color: "#0054A3", border: 2, borderRadius: 2 }}
          className="cursor-pointer"
        />
      </LightTooltipCours>
    );
  };
  const buttonSave = () => {
    return (
      <LightTooltipCours title="Sauvegarder">
        <SaveAsIcon
          onClick={handleSaveNewCourse}
          sx={{ color: "#0054A3", border: 2, borderRadius: 2 }}
          className="cursor-pointer"
        />
      </LightTooltipCours>
    );
  };
  useEffect(() => {
    if ((coursDetails?.isNew && coursDetails?.forChild_id === 0 && coursDetails?.subjectAsked_id === 0)) {
      setLock(true)
    } else {
      setLock(false)
    }
  }, [cours, lock])
  const handleSaveNewCourse = async () => {
    if (validateCourse(coursDetails, setErrors)) {
      setLoad(true)
      let address: any = []
      if (student?.client_id) {
        address = await AddressRepository.getClientAddresses(student?.client_id)
      }
      const address_id = address[0]?.id ? address[0]?.id : cours?.address_id;
      coursDetails.subjectAsked_id = !matiere ? matiereInit?.id : matiere?.id
      coursDetails.forChild = student ? student?.child_id : cours?.forChild_id;
      const courseUpdate = new PunctualCourseDashboard(
        student ? student?.child_id : cours?.forChild_id,
        "",
        address_id,
        !matiere ? matiereInit?.id : matiere?.id,
        coursDetails?.startDatetime,
        coursDetails?.endDatetime,
        false,
        teachr?.id,
        false,
        coursDetails?.duration,
        true,
        false,
        "CARD",
        true,
        "",
        ""
      );
      courseUpdate.startDatetime = coursDetails?.startDatetime;
      courseUpdate.endDatetime = coursDetails?.endDatetime;
      courseUpdate.subjectAskedObject = !matiere ? matiereInit : matiere;
      courseUpdate.subjectAsked = !matiere ? matiereInit?.fr_FR : matiere?.fr_FR;
      courseUpdate.forChildFirstName = student ? student?.child_first_name : cours?.forChildFirstName;
      courseUpdate.forChildLastName = student ? student?.user_last_name : cours?.forChildLastName;
      courseUpdate.isPassedCourse = false;
      courseUpdate.isNew = true;
      courseUpdate.id = cours?.id;
      courseUpdate.teachrPrice = coursDetails?.teachrPrice;
      course2[cours?.id] = courseUpdate;
      StepperStore.setItem(`course${uid}`, { course: course2 });
      setEdit(false);
      setLock(false)
    }
  }
  const formEditCourse = () => {
    return (
      <>
        <td className={`${styleTd()} bg-white`}>
          {/* <FormControlLabel
            label={ */}
          <LightTooltipNoError title={!errorFile.child ? "" : errorFile.child}>
            {cours?.isNew ? (
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                defaultValue={cours}
                onChange={handleChange}
                displayEmpty
                MenuProps={MenuProps}
                input={<BootstrapInput />}
                className={`${!errorFile.child ? "" : "border-[#FF724F]"
                  } border-2 rounded-md`}
                renderValue={(el: any) => {
                  if (!el) {
                    return <span>Ex : John Doe</span>;
                  } else if (el?.child_first_name) {
                    return `${el?.child_first_name} ${el?.user_last_name}`;
                  } else if (el?.forChildFirstName) {
                    return `${el?.forChildFirstName} ${el?.forChildLastName}`;
                  } else {
                    return <span>Ex : John Doe</span>;
                  }
                }}
              >
                <MenuItem disabled value="">
                  <span className="text-slate-400">Ex : John Doe</span>
                </MenuItem>
                {clientTiersPrest
                  ?.filter(
                    (el: any) =>
                      el?.etat_tdp === "OK" &&
                      !el?.is_archive &&
                      !(
                        !el?.is_teachr_contact &&
                        (!el?.price_per_hour || !el?.fees_per_hour)
                      )
                  )
                  .map((el: any) => (
                    <MenuItem
                      sx={{
                        "&.MuiMenuItem-root": {
                          fontFamily: "Nunito, 'sans-serif'",
                          fontSize: "14px",
                        },
                      }}
                      key={el.child_id}
                      value={el}
                    >
                      {`${el?.child_first_name} ${el?.user_last_name}`}
                    </MenuItem>
                  ))}
              </Select>
            ) : (
              <p className="font-nunito text-[#4d4d4d] font-bold text-sm /font-bold tracking-wider">
                {`${capitalizeFirstLetter(
                  cours?.forChildFirstName
                )} ${capitalizeFirstLetter(cours?.forChildLastName)}`}
              </p>
            )}
          </LightTooltipNoError>
          {/* }
            className="font-nunito w-full"
            value={cours?.id}
            control={
              <Checkbox
                checked={checkedItems[cours?.id]?.checked || false}
                onChange={(e) => handleSingleCheck(e.target.checked)}
                name={cours?.id?.toString()}
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon2 />}
              />
            }
          /> */}
        </td>
        <td className={`${styleTd()} bg-white`}>
          <div className="border-2 w-full rounded-md h-full flex">
            <input
              type="text"
              className="w-3/4 pl-2 text-black bg-transparent focus:outline-none focus:ring-0"
              placeholder="Ex : 1h30"
              value={Functions.renderDuration(durationMonth * 2)}
              readOnly
            />
            <div className="border border-white w-1/4">
              <ExpandLessIcon className="/-mb-2" onClick={handleAddDuration} />
              <ExpandMoreIcon
                className="/-mt-2"
                onClick={handleSubtractDuration}
              />
            </div>
          </div>
        </td>
        <td className={`${styleTd()} bg-white`}>
          <LightTooltipNoError
            title={!errorFile.matiere ? "" : errorFile.matiere}
          >
            <div
              className={`${!errorFile.matiere ? "" : "border-[#FF724F]"
                } border-2 rounded-md w-[180px]`}
            >
              <SubjectsSelects
                callBack={getMatiere}
                customClass="w-[100px]"
                customEdit="text-sm"
                sizes="13"
                value={!matiere ? matiereInit : matiere}
                placeholder="Ex : Mathématiques"
              />
            </div>
          </LightTooltipNoError>
        </td>
        <td className={`${styleTd()} bg-white`}>
          <LightTooltipNoError
            className="/-z-50"
            title={!errorFile.startDate ? "" : errorFile.startDate}
            placement="right-start"
          >
            <div
              className={` ${!errorFile.startDate ? "" : "border-[#FF724F]"
                } border-2 rounded-md z-50`}
            >
              <DatePickerValue
                value={startDate}
                size="13"
                callBack={getStartDate}
                minDate={
                  givenMonth === currentMonth
                    ? new Date().setDate(day)
                    : firstDay
                }
              />
            </div>
          </LightTooltipNoError>
        </td>
        <td className={`${styleTd()} bg-white`}>
          <LightTooltipNoError
            className="/-z-50"
            title={!errorFile.endDate ? "" : errorFile.endDate}
            placement="right-start"
          >
            <div
              className={`${!errorFile.endDate ? "" : "border-[#FF724F]"
                } border-2 rounded-md z-50`}
            >
              <EndDatePickerValue
                value={endDate}
                size="13"
                callBack={getEndDate}
                minDate={moment(startDate).toDate()}
              />
            </div>
          </LightTooltipNoError>
        </td>
        <td className={`${styleTd()} bg-white`}>
          <LightTooltipNoError title={!errorFile.price ? "" : errorFile.price}>
            <div
              className={`${!errorFile.price ? "" : "border-[#FF724F]"} ${!teachrPrice ? isTeachrContact ? !cours?.isNew ? "border-2" : 'border-2' : '' : 'border-2'} rounded-md py-4 bg-white`}
            >

              {/* {customFees === undefined ?
                <input
                  type="number"
                  placeholder="Ex : 30"
                  value={priceValid2}
                  className="text-black pl-3"
                  onChange={(e) => setTeachrPrice(e.target.value)}
                />
                : (customFees === "nothing" || customFees?.is_teachr_contact) && !cours?.isNew ? (
                  <input
                    type="number"
                    placeholder="Ex : 30"
                    value={priceValid}
                    className="text-black pl-3"
                    onChange={(e) => setTeachrPrice(e.target.value)}
                  />
                ) : (customFees === "nothing" || customFees?.is_teachr_contact) && cours?.isNew ?
                  <input
                    type="number"
                    placeholder="Ex : 30"
                    value={priceValid2}
                    className="text-black pl-3"
                    onChange={(e) => setTeachrPrice(e.target.value)}
                  /> : (
                    <input
                      type="number"
                      placeholder="Ex : jjj30"
                      value={customFees.price_per_hour}
                      className="text-black pl-3 bg-white"
                      readOnly
                    />
                  )} */}
              {!teachrPrice ? (
                isTeachrContact ? (
                  !cours?.isNew ? (
                    // cours?.teachrPrice?.toString() / (1 - teachr?.defaultFees) / 2).toFixed(2)
                    <input
                      type="number"
                      placeholder="Ex : 30"
                      value={
                        teachrPrice === undefined
                          ? (
                            cours?.teachrPrice?.toString() /
                            (1 - teachr?.defaultFees) /
                            2
                          ).toFixed(2)
                          : teachrPrice
                      }
                      className="text-black pl-3"
                      onChange={(e) => setTeachrPrice(e.target.value)}
                    />
                  ) : (
                    <input
                      type="number"
                      placeholder="Ex : 30"
                      value={
                        teachrPrice === undefined
                          ? parseFloat(cours?.teachrPrice)
                          : teachrPrice
                      }
                      className="text-black pl-3"
                      onChange={(e) => setTeachrPrice(e.target.value)}
                    />
                  )
                ) : ""
              ) : (
                <input
                  type="number"
                  placeholder="Ex : 30"
                  value={priceValid}
                  className="text-black pl-3"
                  onChange={(e) => setTeachrPrice(e.target.value)}
                />
              )}
              {/* {isTeachrContact ? (
                <input
                  type="number"
                  placeholder="Ex : cont 30"
                  value={priceValid}
                  className="text-black pl-3"
                  onChange={(e) => setTeachrPrice(e.target.value)}
                />
              ) : (
                <input
                  type="number"
                  placeholder="Ex : no cont 30"
                  value={priceValid}
                  className="text-black pl-3 bg-white"
                  readOnly
                />
              )} */}
            </div>
          </LightTooltipNoError>
        </td>
        <td className={` px-3 text-first text-sm bg-white`}>
          {cours?.isNew ? (
            <div className="flex gap-2 justify-center items-center">
              {buttonDelete()}
              {buttonSave()}
            </div>
          ) : (
            <button
              onClick={editCourse}
              className="cursor-pointer border-2 mx-auto w-full  border-first p-1 px-1 rounded-lg font-bold hover:text-white hover:bg-first"
            >
              Enregistrer
            </button>
          )}
        </td>
      </>
    );
  };
  return (
    <>
      {edit || lock ? (
        formEditCourse()
      ) : (
        <>
          <td className={`${styleTd()} `}>
            {/* <FormControlLabel
              label={
                <p className="font-nunito text-first text-sm /font-bold tracking-wider">
                  {`${capitalizeFirstLetter(cours?.forChildFirstName)} ${capitalizeFirstLetter(cours?.forChildLastName)}`}
                </p>
              }
              className="font-nunito"
              value={cours?.id}
              control={
                <Checkbox
                  checked={checkedItems[cours?.id]?.checked || false}
                  onChange={(e) => handleSingleCheck(e.target.checked)}
                  name={cours?.id?.toString()}
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon2 />}
                />
              }
            /> */}
            <p className="font-nunito text-first text-sm /font-bold tracking-wider">
              {`${capitalizeFirstLetter(
                cours?.forChildFirstName
              )} ${capitalizeFirstLetter(cours?.forChildLastName)}`}
            </p>
          </td>
          <td className={styleTd()}>
            {Functions.renderDuration(durationMonth * 2)}
          </td>
          <td className={styleTd()}>
            <LightTooltipNoError
              title={!errorFile.matiere ? "" : errorFile.matiere}
            >
              <div className="flex items-center gap-x-1">
                <p
                  className={
                    !errorFile.matiere ? "" : "text-[#FF724F] font-bold"
                  }
                >
                  {
                    // matiere === cours?.subjectAskedObject
                    //   ? cours?.subjectAsked
                    //   :
                    // cours?.isNew ? matiere?.fr_FR : !matiere ? cours?.fr_fr : matiere?.fr_FR
                    !matiere ? matiereInit?.fr_FR : matiere?.fr_FR
                  }
                </p>
                <ErrorOutlineIcon
                  className={
                    !errorFile.matiere ? "hidden" : "text-[#FF724F] w-4"
                  }
                />
              </div>
            </LightTooltipNoError>
          </td>
          <td className={styleTd()}>
            <LightTooltipNoError
              title={!errorFile.startDate ? "" : errorFile.startDate}
            >
              <div className="flex items-center gap-x-1">
                <p
                  className={
                    !errorFile.startDate ? "" : "text-[#FF724F] font-bold"
                  }
                >
                  {moment(startDate).format("DD MMM YYYY")}
                </p>
                <ErrorOutlineIcon
                  className={
                    !errorFile.startDate ? "hidden" : "text-[#FF724F] w-4"
                  }
                />
              </div>
            </LightTooltipNoError>
          </td>
          <td className={styleTd()}>
            <LightTooltipNoError
              title={!errorFile.endDate ? "" : errorFile.endDate}
            >
              <div className="flex items-center gap-x-1">
                <p
                  className={
                    !errorFile.endDate ? "" : "text-[#FF724F] font-bold"
                  }
                >
                  {moment(endDate).format("DD MMM YYYY")}{" "}
                </p>
                <ErrorOutlineIcon
                  className={
                    !errorFile.endDate ? "hidden" : "text-[#FF724F] w-4"
                  }
                />
              </div>
            </LightTooltipNoError>
          </td>
          <td className={styleTd()}>
            <LightTooltipNoError
              title={!errorFile.price ? "" : errorFile.price}
            >
              <div className="flex items-center gap-x-1">
                <p
                  className={!errorFile.price ? "" : "text-[#FF724F] font-bold"}
                >
                  {/* {customFees === undefined
                    ? `${priceValid2}€`
                    : (customFees === "nothing" ||
                        customFees?.is_teachr_contact) &&
                      !cours?.isNew
                    ? `${priceValid}€`
                    : (customFees === "nothing" ||
                        customFees?.is_teachr_contact) &&
                      cours?.isNew
                    ? `${priceValid}€`
                    : `${customFees.price_per_hour}€`} */}
                  {/* {`${!checked ? dispalayPrice + '€' : priceValidHour}`} */}
                  {priceValidHour}
                </p>
                <ErrorOutlineIcon
                  className={!errorFile.price ? "hidden" : "text-[#FF724F] w-4"}
                />
              </div>
            </LightTooltipNoError>
          </td>
          <td
            className={`flex items-center justify-center gap-2 py-3 text-first text-sm`}
          >
            <LightTooltipCours title="Modifier">
              <ModeEditOutlineIcon
                onClick={handleEdit}
                sx={{
                  color: "#0054A3",
                  border: 2,
                  borderRadius: 2,
                }}
                className="cursor-pointer"
              />
            </LightTooltipCours>
            {buttonDelete()}
            <LightTooltipCours title="Déclarer">
              <SendIcon
                onClick={sendingSingleCourse}
                sx={{ color: "#0054A3", border: 2, borderRadius: 2 }}
                className="cursor-pointer"
              />
            </LightTooltipCours>
          </td>
        </>
      )}
    </>
  );
});

export default ComponentCourseForAll;
