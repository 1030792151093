import { useEffect, useState } from "react";
import Input from "../../../../Components/utils/Input";
import SimpleSelect from "../../../../Components/utils/SimpleSelect";
import DateInput from "../../../../Components/utils/DateInput";
import { Button } from "@mui/material";
import validateName from "../../../../Validation/Name";
import validateSelect from "../../../../Validation/Select";
import validateDate from "../../../../Validation/Date";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, UpdateUserEmail } from "../../../../Services/FirebaseService";
import validateEmail from "../../../../Validations/Email";
import Functions from "../../../../Helpers/Functions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { validatePhoneNumber } from "../../../../Validation/Phone";
import SubjectsSelect from '../../../../Components/Dashboard/Account/SubjectSelect'
import { Subject } from "../../../../Objects/models/Subject";
import Teachr from "../../../../Objects/models/Teachr";
import validateSelects from "../../../../Validation/Selects";
import TeachrRepository from "../../../../Repositories/TeachrRepository";
import ApiService from "../../../../Services/ApiService";

function First(props: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [user] = useAuthState(auth);
  const generalTeachr = useSelector((state: any) => state.teachr)
  const [jwtToken, setJwtToken] = useState<string>("");
  const [teachr, setTeachr] = useState<Teachr>()
  const [courses, setCourses] = useState<Subject[]>([]);
  const [option, setOption] = useState<number>();
  const [recommandedBy, setRecommandedBy] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showPhone, setShowPhone] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [birthday, setBirthday] = useState<any>();
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPhone, setErrorPhone] = useState(false);
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    gender: "",
    bithday: "",
    courses: "",
  });
  useEffect(() => {
    if (user) {
      user.getIdToken().then((token) => setJwtToken(token))
      if (user.providerData[0].providerId === "google.com") {
        setShowPhone(true);
      } else {
        setShowPhone(false);
      }
    }
  }, [user])
  useEffect(() => {
    if (generalTeachr?.teachr) {
      setTeachr(generalTeachr?.teachr)
    }
  }, [generalTeachr])
  useEffect(() => {
    if (teachr) {
      setfirstName(teachr?.firstName)
      setLastName(teachr?.lastName)
      setGender(teachr?.sex)
      setBirthday(moment(teachr?.birthday))
    }
  }, [teachr])
  const getCourses = (value: Subject[]) => {
    let array: any[] = [];
    value.map((subject) => array.push(subject["@id"]));
    setCourses(value);
  };
  const getRecommandedBy = (value: string) => {
    setRecommandedBy(value);
  };
  const renderRecommandeScreen = () => {
    if (option !== undefined) {
      if (option === 1 || option === 2) {
        return (
          <div className="w-full">
            <Input
              inset={true}
              defaultValue={recommandedBy}
              callback={getRecommandedBy}
              label="Veuillez nous indiquer le nom de la personne concerné (optionnel)"
              placeholder="Ex : Thomas E..."
              customClass="bg-white py-[6px] shadow-md shadow-[rgba(0,0,0,25%)] mt-4 text-[16px]"
            />
          </div>
        );
      }
    }
    return <div></div>;
  };
  const getOptions = (value: number) => {
    if (value) {
      setOption(value);
    }
  };
  const getFirstName = (value: string) => {
    setfirstName(value);
  };
  const getLastName = (value: string) => {
    setLastName(value);
  };
  const getEmail = (value: string) => {
    setEmail(value);
  };
  const getPhone = (value: string) => {
    setPhone(value);
  };
  const getGender = (value: number) => {
    switch (value) {
      case 1:
        setGender("WOMAN");
        break;

      default:
        setGender("MAN");
        break;
    }
  };
  const getBirthDay = (value: any) => {
    if (value !== "") {
      setBirthday(value);
    }
  };
  const subjectFormat = (subjects: Subject[]) => {
    let result = "";
    subjects.map((subject: Subject, index) => {
      if (index === subjects.length - 1) {
        result += subject.fr_FR;
      } else {
        result += subject.fr_FR + ", ";
      }
    });
    return result;
  };
  const validate = () => {
    let erremail = false
    let errfirstname = true;
    let errlastname = true;
    let errgender = true;
    let errbirthday = true;
    let errcourses = true;

    if (user?.providerData[0].providerId === 'phone') {
      erremail = true
      if (!validateEmail(email).isValid) {
        setErrorEmail(validateEmail(email).message)
        erremail = true
      } else {
        setErrorEmail(validateEmail(email).message)
        erremail = false
      }
    }
    if (user?.providerData[0].providerId === 'google.com') {
      if (validatePhoneNumber(phone) !== "") {
        setErrorPhone(true)
      } else {
        setErrorPhone(false)
      }
    }
    if (!validateName(firstName).isValid) {
      setErrors({
        firstname: validateName(firstName).message,
        lastname: validateName(lastName).message,
        gender: validateSelect(gender).message,
        bithday: validateDate(birthday).message,
        courses: validateSelects(courses).message
      });
      errfirstname = true;
    } else {
      setErrors({
        firstname: validateName(firstName).message,
        lastname: validateName(lastName).message,
        gender: validateSelect(gender).message,
        bithday: validateDate(birthday).message,
        courses: validateSelects(courses).message
      });
      errfirstname = false;
    }
    if (!validateName(lastName).isValid) {
      setErrors({
        firstname: validateName(firstName).message,
        lastname: validateName(lastName).message,
        gender: validateSelect(gender).message,
        bithday: validateDate(birthday).message,
        courses: validateSelects(courses).message
      });
      errlastname = true;
    } else {
      setErrors({
        firstname: validateName(firstName).message,
        lastname: validateName(lastName).message,
        gender: validateSelect(gender).message,
        bithday: validateDate(birthday).message,
        courses: validateSelects(courses).message
      });
      errlastname = false;
    }
    if (!validateSelect(gender).isValid) {
      setErrors({
        firstname: validateName(firstName).message,
        lastname: validateName(lastName).message,
        gender: validateSelect(gender).message,
        bithday: validateDate(birthday).message,
        courses: validateSelects(courses).message
      });
      errgender = true;
    } else {
      setErrors({
        firstname: validateName(firstName).message,
        lastname: validateName(lastName).message,
        gender: validateSelect(gender).message,
        bithday: validateDate(birthday).message,
        courses: validateSelects(courses).message
      });
      errgender = false;
    }
    if (!validateDate(birthday).isValid) {
      setErrors({
        firstname: validateName(firstName).message,
        lastname: validateName(lastName).message,
        gender: validateSelect(gender).message,
        bithday: validateDate(birthday).message,
        courses: validateSelects(courses).message
      });
      errbirthday = true;
    } else {
      setErrors({
        firstname: validateName(firstName).message,
        lastname: validateName(lastName).message,
        gender: validateSelect(gender).message,
        bithday: validateDate(birthday).message,
        courses: validateSelects(courses).message
      });
      errbirthday = false;
    }

    if (!validateSelects(courses).isValid) {
      setErrors({
        firstname: validateName(firstName).message,
        lastname: validateName(lastName).message,
        gender: validateSelect(gender).message,
        bithday: validateDate(birthday).message,
        courses: validateSelects(courses).message,
      });
      errcourses = true;
    } else {
      setErrors({
        firstname: validateName(firstName).message,
        lastname: validateName(lastName).message,
        gender: validateSelect(gender).message,
        bithday: validateDate(birthday).message,
        courses: ''
      });
      errcourses = false;
    }

    if (errlastname || errfirstname || errgender || errbirthday || erremail || errcourses || errorPhone) return false;
    else return true;
  };
  const handleSubmit = (event: any) => {
    if (validate() && user && teachr) {
      // if (teachr) {
        setShow(true)
        teachr.firebaseAuthToken = jwtToken;
        teachr.firstName = firstName
        teachr.lastName = lastName
        teachr.sex = gender
        teachr.birthday = birthday.format('YYYY/MM/DD')
        const description = `Bonjour,
        Je m’appelle ${firstName} et j’ai ${moment().subtract(moment(birthday.format('YYYY/MM/DD')).format('YYYY'), 'year').format('YY')} ans. 
        Je suis professeur particulier en ${subjectFormat(courses)} et inscrit sur Teach’r depuis ${moment().format('MMMM YYYY')}.
        Au plaisir d’échanger avec vous,
        Cordialement`
        teachr.description = description
        // teachr.defaultFees = "0.125";
        teachr.isOldFacturation = false;
        if (user.providerData[0].providerId === "google.com") {
          teachr.phone = Functions.formatPhoneNumber(phone)
        }
        if (courses.length > 0) {
          let subjectsId: string[] = [];
          courses.map((subject: Subject) => {
            subjectsId.push(subject["@id"]!);
          });
          teachr.subjectToTeach = subjectsId;
        }
        if (option) {
          if (recommandedBy && recommandedBy !== "") {
            teachr.recommandedBy = recommandedBy;
          } else {
            teachr.recommandedBy = Functions.recommandedByDatas[option - 1];
          }
        }
        // if (teachr.isTdae) {
        //   teachr.statusTdae = '10'
        // }
        TeachrRepository.createTeachr(teachr).then((responseTeachr) => {
          ApiService.enableAuth(undefined, undefined, "teachr " + jwtToken)
          dispatch({
            type: "SET_TEACHR_INFO",
            payload: {
              teachr: responseTeachr,
              authorization: "teachr " + jwtToken,
            },
          });
          props.nextStep(2);
          setShow(false);
        }).catch((error) => {
          setShow(false);
          if (error._description.search("This user is already registered.") > -1) {
            TeachrRepository.getCurrentTeachr().then((response) => {
              setTeachr(response)
            }).catch(() =>
              document.getElementById('teachr-loader')?.classList.add('hidden'))
          } else {
            document.getElementById('teachr-loader')?.classList.add('hidden')
          }
        })
      // }
    }
    event.preventDefault();
  };
  const handleRegister = (event: any) => {
    if (user?.providerData[0].providerId === 'phone') {
      if (teachr) {
        teachr.phone = user?.phoneNumber ? Functions.formatPhoneNumber(user.phoneNumber) : ""
        UpdateUserEmail(email).then(() => {
          handleSubmit(event)
        }).catch((error) => {
          console.warn(error);
          setErrorEmail('Ce mail est déjà utilisé')
        });
      }
    } else {
      handleSubmit(event)
    }
  }
  const renderGenderInput = () => {
    switch (teachr && teachr?.sex) {
      case 'MAN':
        return (<SimpleSelect
          callback={getGender}
          label="Vous êtes"
          data={["Une femme", "Un homme"]} customClass='bg-white py-[6px] shadow-md shadow-[rgba(0,0,0,25%)] text-[16px] '
          defaultValue={2}
        />)
      case "WOMAN":
        return (<SimpleSelect
          callback={getGender}
          label="Vous êtes"
          data={["Une femme", "Un homme"]} customClass='bg-white py-[6px] shadow-md shadow-[rgba(0,0,0,25%)] text-[16px] '
          defaultValue={1}
        />)
      default:
        return (<SimpleSelect
          callback={getGender}
          label="Vous êtes"
          data={["Une femme", "Un homme"]} customClass='bg-white py-[6px] shadow-md shadow-[rgba(0,0,0,25%)] text-[16px] '
        />)
    }
  }
  return (
    <div>
      <p className="pb-10 lg:leading-[61px] lg:text-[45px] leading-[30px] text-[22px] text-white font-extrabold">
        Votre compte a été créé, <br/> veuillez compléter votre profil :{" "}
      </p>
      <div className="flex flex-col justify-start gap-x-20 lg:w-full lg:flex-row items-start">
        <div className="md:w-3/4 w-full ">
          <div className="flex flex-col gap-y-2">
            <div className="flex flex-col md:flex-row gap-x-4">
              <div className="w-full">
                <p className="h-6 font-bold text-third">
                  {errors.firstname === "" ? null : errors.firstname}
                </p>
                <Input
                  inset={true}
                  callback={getFirstName}
                  label="Prénom"
                  placeholder="John"
                  customClass="bg-white py-[6px] shadow-md shadow-[rgba(0,0,0,25%)] text-[16px]"
                  defaultValue={
                    teachr && teachr?.firstName !== ""
                      ? teachr?.firstName
                      : null
                  }
                />
              </div>
              <div className="w-full">
                <p className="h-6 font-bold text-third">
                  {errors.lastname === "" ? null : errors.lastname}
                </p>
                <Input
                  inset={true}
                  callback={getLastName}
                  label="Nom"
                  placeholder="Doe"
                  customClass="bg-white py-[6px] shadow-md shadow-[rgba(0,0,0,25%)] text-[16px] "
                  defaultValue={
                    teachr && teachr?.lastName !== "" ? teachr?.lastName : null
                  }
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-x-4">
              <div className="w-full">
                <p className="h-6 font-bold text-third">
                  {errors.gender === "" ? null : errors.gender}
                </p>
                {renderGenderInput()}
              </div>
              <div className="w-full">
                <p className="h-6 font-bold text-third">
                  {errors.bithday === "" ? null : errors.bithday}
                </p>
                {(
                  <DateInput
                    callback={getBirthDay}
                    defaultValue={teachr?.birthday ? teachr?.birthday : null}
                  />
                )}
              </div>
            </div>
            {user?.phoneNumber && (
              <div className="">
                <p className="h-6 font-bold text-third">
                  {errorEmail === "" ? null : errorEmail}
                </p>
                <Input
                  inset={true}
                  callback={getEmail}
                  label="E-mail"
                  placeholder="mail@mail.com"
                  customClass="bg-white py-[6px] shadow-md shadow-[rgba(0,0,0,25%)] text-[16px]"
                />
              </div>
            )}
            {showPhone && (
              <div className="">
                <p className="h-6 font-bold text-third">
                  {errorPhone ? validatePhoneNumber(phone) : ""}
                </p>
                <Input
                  inset={true}
                  callback={getPhone}
                  label="Numéro de téléphone"
                  placeholder="06 XX XX XX XX"
                  customClass="bg-white py-[6px] shadow-md shadow-[rgba(0,0,0,25%)] text-[16px]"
                />
              </div>
            )}
          </div>
        </div>
        <div className="md:w-3/4 w-full flex flex-col gap-y-8 md:px-10">
          <div className="box-content h-full ">
            <p className="h-6 font-bold text-third">
              {errors.courses === "" ? null : errors.courses}
            </p>
            <SubjectsSelect
              default={courses}
              label="Quelle(s) matière(s) souhaitez-vous enseigner ?"
              placeholder="SVT, piano, anglais..."
              getSubjects={getCourses}
              customClass="bg-white py-[6px] shadow-md shadow-[rgba(0,0,0,25%)] text-[16px]"
              badgeClass=" border-white text-white"
            />
          </div>
          <div className="/flex /flex-col /gap-y-4">
            <div className="/flex /flex-col /md:flex-row /gap-4">
              <div className="w-full">
                <SimpleSelect
                  defaultValue={option}
                  callback={getOptions}
                  label="Comment avez-vous entendu parler de nous ? :"
                  data={Functions.recommandedByDatas}
                  customClass="bg-white py-[6px] shadow-md shadow-[rgba(0,0,0,25%)] text-[16px] "
                />
              </div>
              {renderRecommandeScreen()}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col py-3 md:flex-row md:w-1/2 md:px-20 justify-between md:mt-10">
        <Button
          className="w-full px-4 py-2 font-extrabold text-white uppercase rounded-full md:order-last font-nunito bg-second"
          disabled={show}
          onClick={(e) => {
            handleRegister(e);
          }}
        >
          suivant
        </Button>
        <Button
          className=" bg-transparent rounded-full font-nunito font-extrabold py-2 text-white  focus:border-solid border-[1px] uppercase px-4 w-full"
          onClick={() => navigate(-1)}
        >
          précédent
        </Button>
      </div>
    </div>
  );
}

export default First;
