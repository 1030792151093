import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PunctualCourseRepository from '../../../../../../Repositories/PunctualCourseRepository';
import SubscriptionCourseRepositorys from '../../../../../../Repositories/SubscriptionCourseRepository';
import TeachrRepository from '../../../../../../Repositories/TeachrRepository';
import ComponentYearPriceChart from "./ComponentDurationChart/ComponentYearPriceChart";
import ComponentWeekPriceChart from './ComponentDurationChart/ComponentWeekPriceChart';
import ComponentMonthPriceChart from './ComponentDurationChart/ComponentMonthPriceChart';
import nouneuro from "../../../../../../Assets/Icons/noun-euro-2.svg"
import info from "../../../../../../Assets/Icons/info-acre.svg";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

const ChartPayment = () => {

    const [cours, setCourse] = useState([]);
    const [durationByDay, setDurationByDay] = useState({});
    const [durationByDay2, setDurationByDay2] = useState({});
    const [durationByDay3, setDurationByDay3] = useState({});
    const [durationByMonth, setDurationByMonth] = useState({});
    const [durationByMonth2, setDurationByMonth2] = useState({});
    const [durationByMonth3, setDurationByMonth3] = useState({});
    const [durationByYear, setDurationByYear] = useState({});
    const [durationByYear2, setDurationByYear2] = useState({});
    const [durationByYear3, setDurationByYear3] = useState({});
    const [current, setCurrent] = useState(2)
    const monthActive = moment().format("MMMM")
    const yearActive = moment().subtract(1, 'year').format("YYYY")
    const yearActive2 = moment().format("YYYY")
    const priceWeek = Object.values(durationByDay).reduce((a, b) => a + b, 0)
    const priceWeek2 = Object.values(durationByDay2).reduce((a, b) => a + b, 0)
    const priceWeek3 = Object.values(durationByDay3).reduce((a, b) => a + b, 0)
    const priceMonth = (durationByMonth[monthActive])
    const priceMonth2 = (durationByMonth2[monthActive])
    const priceMonth3 = (durationByMonth3[monthActive])
    const priceYear = ((durationByYear[yearActive]) + (durationByYear[yearActive2]))?.toFixed(1)
    const priceYear2 = ((durationByYear2[yearActive]) + (durationByYear2[yearActive2]))?.toFixed(1)
    const priceYear3 = ((durationByYear3[yearActive]) + (durationByYear3[yearActive2]))?.toFixed(1)
    const priceYear1 = (durationByYear[yearActive2])?.toFixed(1)
    const priceYear21 = (durationByYear2[yearActive2])?.toFixed(1)
    const priceYear31 = (durationByYear3[yearActive2])?.toFixed(1)
    const [open, setOpen] = useState(false)

    const handleChange = (val) => {
        setCurrent(val)
    }
    const handlePosition = (val) => {
        switch (val) {
            case 1:
                return <ComponentWeekPriceChart durationByDay={durationByDay} durationByDay2={durationByDay2} durationByDay3={durationByDay3} priceWeek2={priceWeek2} priceWeek={priceWeek} priceWeek3={priceWeek3} />
            case 2:
                return <ComponentMonthPriceChart durationByMonth={durationByMonth} durationByMonth2={durationByMonth2} durationByMonth3={durationByMonth3} priceMonth2={priceMonth2} priceMonth={priceMonth} priceMonth3={priceMonth3} />
            case 3:
                return <ComponentYearPriceChart durationByYear={durationByYear} durationByYear2={durationByYear2} durationByYear3={durationByYear3} priceYear2={priceYear2} priceYear={priceYear} priceYear3={priceYear3} priceYear21={priceYear21} priceYear1={priceYear1} priceYear31={priceYear31} />
            default:
                return <ComponentWeekPriceChart durationByDay={durationByDay} />
        }
    }
    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((teachr) => {
            if (teachr.id) {
                const coursePromises = [
                    PunctualCourseRepository.teachrGetCourseForDashboard(teachr.id),
                    SubscriptionCourseRepositorys.getActiveChargeForSubscriptionCourseByTeachrForDashboard(teachr.id)
                ];

                Promise.all(coursePromises).then(([punctualCourses, subscriptionCourses]) => {
                    const combinedCourses = punctualCourses.concat(subscriptionCourses);
                    combinedCourses.sort((a, b) => {
                        // Trier en fonction de la durée et de l'heure de début
                        const aDuration = a.duration || a.durationPerDay;
                        const bDuration = b.duration || b.durationPerDay;
                        const astartDateOccurrence = a.startDateTime || a.startDateOccurrence;
                        const bstartDateOccurrence = b.startDateTime || b.startDateOccurrence;

                        if (aDuration !== bDuration) {
                            return aDuration - bDuration;
                        } else {
                            return new Date(astartDateOccurrence) - new Date(bstartDateOccurrence);
                        }
                    });
                    const coursesWithCommonAttributes = combinedCourses.map((course) => {
                        // Créer deux attributs communs à partir des différentes propriétés
                        const duration = course.durationPerDay || course.duration;
                        const startDateTime = course.startDateOccurrence || course.startDateTime;

                        return { ...course, duration, startDateTime };
                    });
                    setCourse(coursesWithCommonAttributes);
                });
            }
        });
    }, []);
    useEffect(() => {
        // Calcul des dates de la semaine en cours
        // const debutAnnee = moment().startOf("year");
        const debutAnnee = moment().subtract(1, 'year').startOf('year');
        const datesAnnee = [];
        const debutSemaine = moment().startOf("isoWeek");
        const datesSemaine = [];

        for (let i = 0; i < 7; i++) {
            datesSemaine.push(moment(debutSemaine).add(i, "days").format("YYYY-MM-DD"));
        }
        for (let i = 0; i < 12; i++) {
            datesAnnee.push(moment(debutAnnee).add(i, "months").format("MMMM"));
        }
        const coursSemaine = cours.filter((cours) =>
            datesSemaine.includes(moment(cours.startDatetime || cours.startDateOccurrence).format("YYYY-MM-DD"))
        );
        const coursMois = cours.filter((cours) =>
            moment(cours.startDatetime || cours.startDateOccurrence).isSameOrAfter(moment(), "year")
        );
        const coursAnnee = cours.filter((cours) =>
            moment(cours.startDatetime || cours.startDateOccurrence).isSameOrAfter(moment().subtract(1, 'year'), "year")
        );
        // Calcul de la durée totale de chaque jour
        const durationByDay = {};
        const durationByDay2 = {};
        const durationByDay3 = {};
        const durationByDay4 = {};
        const durationByDayInHour = [];

        for (let i = 0; i < 7; i++) {
            durationByDay[moment(debutSemaine).add(i, "days").format("dd")] = 0;
            durationByDay2[moment(debutSemaine).add(i, "days").format("dd")] = 0;
            durationByDay3[moment(debutSemaine).add(i, "days").format("dd")] = 0;
            durationByDay4[moment(debutSemaine).add(i, "days").format("dd")] = 0;
        }
        coursSemaine.forEach((cours) => {
            const jour = moment(cours.startDatetime || cours.startDateOccurrence).format("dd");

            const priceComponent = cours?.priceCf?.priceNoFees
            if (cours?.urssafServiceStatus !== null || cours?.urssafServiceStatus !== null) {
                if (["INTEGRATED", "10", "WAITING_FOR_VALIDATION", "20"].includes(cours.urssafServiceStatus)) {
                    durationByDay2[jour] += priceComponent;
                } else if (["PAID", "VALIDATED", "COLLECTED", "COVERED"].includes(cours.urssafServiceStatus)) {
                    durationByDay[jour] += priceComponent;
                } else {
                    durationByDay3[jour] += priceComponent;
                }
            } else if (cours?.chargeIncvoice === null) {
                durationByDay2[jour] += priceComponent;
            }

        })

        Object.values(durationByDay).forEach((day) => {
            durationByDayInHour.push(day);
        });
        Object.values(durationByDay2).forEach((day) => {
            durationByDayInHour.push(day);
        });
        Object.values(durationByDay3).forEach((day) => {
            durationByDayInHour.push(day);
        });
        Object.values(durationByDay4).forEach((day) => {
            durationByDayInHour.push(day);
        });

        // Calcul de la durée totale de chaque mois
        const durationByMonth = {};
        const durationByMonth2 = {};
        const durationByMonth3 = {};
        const durationByMonth4 = {};
        const durationByMonthInHour = [];
        for (let i = 0; i < 30; i++) {
            durationByMonth[moment(debutAnnee).add(i, "months").format("MMMM")] = 0;
            durationByMonth2[moment(debutAnnee).add(i, "months").format("MMMM")] = 0;
            durationByMonth3[moment(debutAnnee).add(i, "months").format("MMMM")] = 0;
            durationByMonth4[moment(debutAnnee).add(i, "months").format("MMMM")] = 0;
        }
        coursMois.forEach((cours) => {
            const month = moment(cours.startDatetime).format("MMMM");

            const priceComponent = cours?.priceCf?.priceNoFees;
            if (cours?.urssafServiceStatus !== null || cours?.urssafServiceStatus !== null) {
                if (["INTEGRATED", "10", "WAITING_FOR_VALIDATION", "20"].includes(cours.urssafServiceStatus)) {
                    durationByMonth2[month] += priceComponent;
                } else if (["PAID", "VALIDATED", "COLLECTED", "COVERED"].includes(cours.urssafServiceStatus)) {
                    durationByMonth[month] += priceComponent;
                }
                else {
                    durationByMonth3[month] += priceComponent;
                }
            } else if (cours?.chargeIncvoice === null) {
                durationByMonth2[month] += priceComponent;
            }

        });
        Object.values(durationByMonth).forEach((month) => {
            durationByMonthInHour.push(month);
        });
        Object.values(durationByMonth2).forEach((month) => {
            durationByMonthInHour.push(month);
        });
        Object.values(durationByMonth3).forEach((month) => {
            durationByMonthInHour.push(month);
        });
        // Calcul de la durée totale de chaque mois
        const durationByYear = {};
        const durationByYear2 = {};
        const durationByYear3 = {};
        const durationByYear4 = {};
        const durationByYearInHour = [];
        for (let i = -1; i < 4; i++) {
            durationByYear[moment(debutAnnee).add(i, "year").format("YYYY")] = 0;
            durationByYear2[moment(debutAnnee).add(i, "year").format("YYYY")] = 0;
            durationByYear3[moment(debutAnnee).add(i, "year").format("YYYY")] = 0;
            durationByYear4[moment(debutAnnee).add(i, "year").format("YYYY")] = 0;
        }
        coursAnnee.forEach((cours) => {
            const year = moment(cours.startDatetime || cours.startDateOccurrence).format("YYYY");

            const priceComponent = cours?.priceCf?.priceNoFees;
            if (cours?.urssafServiceStatus !== null || cours?.urssafServiceStatus !== null) {
                if (["INTEGRATED", "10", "WAITING_FOR_VALIDATION", "20"].includes(cours.urssafServiceStatus)) {
                    durationByYear2[year] += priceComponent;
                } else if (["PAID", "VALIDATED", "COLLECTED", "COVERED"].includes(cours.urssafServiceStatus)) {
                    durationByYear[year] += priceComponent;
                }
                else {
                    durationByYear3[year] += priceComponent;
                }
            } else if (cours?.chargeIncvoice === null) {
                durationByYear2[year] += priceComponent;
            }
        });
        Object.values(durationByYear).forEach((year) => {
            durationByYearInHour.push(year);
        });
        Object.values(durationByYear2).forEach((year) => {
            durationByYearInHour.push(year);
        });
        Object.values(durationByYear3).forEach((year) => {
            durationByYearInHour.push(year);
        });
        Object.values(durationByYear4).forEach((year) => {
            durationByYearInHour.push(year);
        });
        setDurationByDay(durationByDay);
        setDurationByDay2(durationByDay2);
        setDurationByDay3(durationByDay3);
        setDurationByMonth(durationByMonth);
        setDurationByMonth2(durationByMonth2);
        setDurationByMonth3(durationByMonth3);
        setDurationByYear(durationByYear);
        setDurationByYear2(durationByYear2);
        setDurationByYear3(durationByYear3);
    }, [cours]);

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Collapse in={open}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ fontFamily: 'nunito' }}
                        severity="info"
                    >
                        Montant facturé à l'élève
                    </Alert>
                </Collapse>

            </Box>
            <div className="text-dark_blue p-2 h-[100%]  border-first">
                <div className=' h-[30%] p-2 space-y-6'>
                    <div className=" flex gap-x-2 items-center mx-auto //w-4/5">
                        <div className=" p-1 rounded-md border-2 border-[#E8F5FF] flex items-center md:p-2 bg-[#E8F5FF]">
                            <img src={nouneuro} alt="" />
                        </div>
                        <div className=' md:pt-2 pt-1'>
                            <p className="font-extrabold text-first xl:text-xl lg:text-lg md:text-base text-sm border-b-2 border-first rounded-lg px-2 pb-2">Mon Chiffre d’affaires :</p>
                        </div>
                        <Button
                            disabled={open}
                            variant="outlined"
                            onClick={() => {
                                setOpen(true);
                            }}
                        >
                            <img src={info} alt="" className={open ? 'text-gray-300' : ''} />
                        </Button>
                    </div>
                    <div className="space-y-2">
                        <div className=" //mx-auto flex mt-2  bg-[#E8F5FF] mx-auto text-center font-extrabold  rounded-xl md:w-3/4 " >
                            <div className={current === 1 ? "bg-first rounded-xl text-white w-1/3 pt-1  cursor-pointer" : "bg-[#E8F5FF] text-first  pt-1 w-1/3  cursor-pointer"}
                                onClick={() => handleChange(1)}
                            >Semaine</div>
                            <div className={current === 2 ? "bg-first rounded-xl text-white w-1/3   pt-1 cursor-pointer" : "bg-[#E8F5FF] text-first w-1/3   pt-1 cursor-pointer"}
                                onClick={() => handleChange(2)}
                            >Mois</div>
                            <div className={current === 3 ? "bg-first rounded-xl text-white w-1/3   pt-1 cursor-pointer" : "bg-[#E8F5FF] text-first w-1/3   pt-1 cursor-pointer"}
                                onClick={() => handleChange(3)}
                            >Année</div>
                        </div>
                    </div>
                    {
                        current === 1 ?
                            <p className="pl-3 font-bold text-sm text-[#4d4d4d] w-4/5 mx-auto">Sur les jours de la semaine</p>
                            : current === 2 ?
                                <p className="pl-3 font-bold text-sm text-[#4d4d4d] w-4/5 mx-auto">Le chiffre d'affaire sur le mois en cours</p>
                                :
                                <p className="pl-3 font-bold text-sm text-[#4d4d4d] w-4/5 mx-auto">Sur les 2 dernières années</p>
                    }
                </div>

                <div className="mx-auto lg:relative h-[70%]">
                    {handlePosition(current)}
                </div>
            </div>
        </>
    );
};

export default ChartPayment;
