import add from "../../../../Assets/Icons/add.svg";
import { Dialog, RadioGroup, DialogProps, Tooltip } from "@mui/material";
import Navbar from "../../../../Components/Admin/Navbar";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import TeachrRepository from "../../../../Repositories/TeachrRepository";
import ComponentChildStudent from "./AddStudent/ComponentChildStudent";
import TeachrContactRepository from "../../../../Repositories/TeachrContactRepository";
import AddSutentValid from "./AddStudent/AddSutentValid";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import moment from "moment";
import React from "react";
import ComponentArchive from "./AddStudent/ComponentArchive";
import { validatePhoneNumber } from "../../../../Validation/Phone";
import validateEmail from "../../../../Validation/Email";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Functions from "../../../../Helpers/Functions";
import CustomFees from "../../../../Objects/models/CustomFees";
import CustomFeesRepository from "../../../../Repositories/CustomFeesRepository";
import CopyToClipboard from "react-copy-to-clipboard";
import ClientRepository from "../../../../Repositories/ClientRepository";
import TeachrContact from "../../../../Objects/models/TeachrContact";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import {
  LightTooltipCours,
  buttonArchivage,
  buttonDelete,
  handleDeclare,
  profileStudent,
  removeExtraSpace,
} from "../Dashboard/UtilsDashboard/FunctionsDashbord";
import { checkIfUserExist } from "../../../../Services/FirebaseService";
import config from "../../../../config";
import { Skeleton } from "@mui/material";
import add_srudent from "../../../../Assets/Images/ponct_dash.svg";
import PaginationComponent from "./CourseType/CoursePunctual/ActivesPunctuals/Pagination";
import info from "../../../../Assets/Icons/info-acre.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";

interface dataProfileModel {
  handleOpenView: () => void;
  handleClose: () => void;
  fullName: string;
  initFullName: string;
  child: any;
  isLoad2: boolean;
  firstCondition: boolean;
  isDelete2: boolean;
  openView: boolean;
  address: any;
  isTrue: boolean;
  handleRefresh: () => void;
}
interface dataDeleteModel {
  child: any;
  setLoad: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteTrue2: (val: boolean) => void;
  handleLoadDelete2: () => void;
  setIsLoad2: React.Dispatch<React.SetStateAction<boolean>>;
  navigate: any;
  onValueChange2: React.Dispatch<React.SetStateAction<boolean>>;
}
interface dataArchiveModel {
  child: any;
  setLoad: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteTrue2: (val: boolean) => void;
  handleLoadDelete2: () => void;
  navigate: any;
  onValueChange2: React.Dispatch<React.SetStateAction<boolean>>;
}
interface btnArchiveModel {
  data: dataArchiveModel;
  load: boolean;
  firstCondition: boolean;
}
interface btnDeleteModel {
  data: dataDeleteModel;
  load: boolean;
  firstCondition: boolean;
}

interface dataDeclareModel {
  child: any;
  firstCondition: boolean;
  nextDeclareCourse: () => void;
  fullName: string;
}

const useDisplayAddStudent = (childs: any, callback: () => void) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [teachr, setTeachr] = useState<any>();
  const [openView, setOpenView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isExist, setIsExist] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [teachrCode, setTeachrCode] = useState<string>();
  const [link, setLink] = useState<string>("");
  const [isCopy, setIsCopy] = useState(false);
  const [showDialog2, setShowDialog2] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("lg");
  const [errors, setErrors] = useState({
    mail: false,
    telephone: false,
  });
  useEffect(() => {
    TeachrRepository.getCurrentTeachr().then((response) => {
      if (response && response.id) {
        setTeachrCode(response.invitationCode);
        setTeachr(response);
      }
    });
  }, []);
  const handleOpenView = () => {
    setOpenView(true);
  };
  const handleClose = () => {
    setEmail("");
    setPhone("");
    setLastName("");
    setFirstName("");
    if (showDialog) {
      setShowDialog(false);
      setOpenView(false);
    } else if (showDialog2) {
      setShowDialog2(false);
      setOpenView(false);
    } else {
      setOpenView(false);
    }
  };
  const validation = (): boolean => {
    let valid = {
      mail: false,
      telephone: false,
    };

    valid.mail = !validateEmail(email).isValid;
    if (validatePhoneNumber(phone) === "") {
      valid.telephone = false;
    } else {
      valid.telephone = true;
    }

    setErrors(valid);
    if (!valid.mail && !valid.telephone) {
      return true;
    } else {
      return false;
    }
  };
  const sendTeachrCode = () => {
    if (teachr && teachr.id) {
      ClientRepository.sendTeachrCode(email, teachr.id).then((res) => {
        handleClose();
        dispatch({
          type: "SHOW_SUCCESS",
          payload:
            "Votre client vient de recevoir un mail contenant votre code de parrainage.",
        });
      });
    }
  };
  const handleCreateTeachrContact = () => {
    const teachrContact = new TeachrContact(
      0,
      removeExtraSpace(email),
      teachr["@id"]!
    );
    teachrContact.phoneNumber = phone!;
    if (firstName) {
      teachrContact.studentName = firstName!;
    }
    if (lastName) {
      teachrContact.name = lastName!;
    }
    checkIfUserExist(teachrContact.mail).then((signInMethods) => {
      if (signInMethods && signInMethods.length > 0) {
        handleClose();
        dispatch({
          type: "SHOW_WARNING",
          payload:
            "Cet e-mail est déjà rattaché à un compte , merci de le changer pour continuer.",
        });
        setLoading(false);
      } else {
        TeachrContactRepository.createTeachrContact(teachrContact)
          .then((response) => {
            let linkProd = `https://app.teachr.fr/client/complete-infos/${response.key}`;
            let linkStaging = `https://staging.teachr.fr/client/complete-infos/${response.key}`;
            setShowDialog2(true);
            if (config.debugMode) {
              setLink(linkStaging);
            } else {
              setLink(linkProd);
            }
            setLoading(false);
            callback();
          })
          .catch((errors) => {
            setLoading(false);
            handleClose();

            switch (errors._description) {
              case "Invalid Authorization header.":
                dispatch({
                  type: "SHOW_ERROR",
                  payload: "Votre session a expiré, Veuillez vous reconnecter!",
                });
                navigate("/teachr/login");
                break;
              case "Constraint violation":
                errors._arrayInvalidAttributes.map((error: any) => {
                  if (error.fieldName === "mail") {
                    dispatch({
                      type: "SHOW_WARNING",
                      payload:
                        "L'adresse e-mail que vous avez saisie n'est pas valide. Veuillez entrer une adresse e-mail correcte, sans accents.",
                    });
                  }
                });
                break;

              default:
                dispatch({
                  type: "SHOW_ERROR",
                  payload:
                    "Erreur inattendu, merci de nous contacter sur support@teachr.fr",
                });
                break;
            }
          });
      }
    });
  };
  const handleAddStudent = () => {
    if (validation()) {
      if (isExist === false) {
        setLoading(true);
        if (teachr?.id) {
          setLoading(true);
          TeachrRepository.addFavoriteTeachr(
            teachr?.id,
            email,
            Functions.formatPhoneNumber(phone)
          )
            .then((client: any) => {
              if (client["@id"] && teachr["@id"]) {
                const customFees = new CustomFees(
                  [client["@id"]],
                  [teachr["@id"]],
                  null,
                  null,
                  client.isRelationTeachr ? false : true
                );
                CustomFeesRepository.createCustomFees(customFees)
                  .then((res) => {
                    // setIsExist(true);
                    setLoading(true);
                    callback();
                    handleClose();
                    dispatch({
                      type: "SHOW_SUCCESS",
                      payload:
                        "Le client que vous venez d'ajouter possède déjà un compte chez Teach'r, et l'ajout a été effectué avec succès. Vous pouvez donc maintenant commencer à déclarer ses cours.",
                    });
                    setLoading(false);
                  })
                  .catch((err) => {
                    console.warn(err);
                    setIsExist(false);
                    setLoading(false);
                  });
              }
            })
            .catch((err) => {
              setLoading(false);
              console.warn(err);
              switch (err._description) {
                case "Client not found.":
                  handleCreateTeachrContact();
                  break;
                case "Client don't have idTiersPrestation":
                  setShowDialog(true);
                  break;
                case "you are already register this client":
                  handleClose();
                  dispatch({
                    type: "SHOW_WARNING",
                    payload:
                      'Le client est déjà enregistré et fait partie de vos clients. Veuillez vérifier dans "LISTE DE MES ÉLÈVES AYANT COMPLÉTÉ LE FORMULAIRE".',
                  });
                  break;
                default:
                  handleClose();
                  dispatch({
                    type: "SHOW_ERROR",
                    payload: "Erreur inconnue",
                  });
                  break;
              }
            });
        }
      } else {
        //  handleNextStep();
        // const teachrContact = new TeachrContact(
        //   0,
        //   removeExtraSpace(email),
        //   teachr["@id"]!
        // );
        // teachrContact.phoneNumber = phone!;
        // handleClose()
      }
    }
  };
  return (
    <div className="">
      <div
        onClick={handleOpenView}
        className="cursor-pointer text-center xl:text-xl lg:text-lg md:text-base text-sm font-extrabold text-dark_blue flex space-x-2"
      >
        <p>Ajouter un élève 👩‍💻</p>
      </div>
      <Dialog
        maxWidth={maxWidth}
        open={openView}
        onClose={handleOpenView}
        sx={{}}
      >
        <div className="text-left /p-5 lg:w-[650px]">
          <div className="/border-2 w-full mt-4 rounded-lg p-4 h-[50%] /shadow-dashboard-setup-intro-form">
            <div className=" pb-4 w-full ">
              <h1 className="text-lg font-extrabold underline md:text-2xl text-first underline-offset-8">
                {showDialog2 ? "Félicitations 🥳 " : "Ajout d’un élève ✏️"}
              </h1>
            </div>
            <Swiper
              className="h-full"
              slidesPerView={1}
              // loop
              speed={700}
              navigation={{ nextEl: ".next-slide", prevEl: ".prev-slide" }}
              modules={[Navigation]}
              effect="slide"
              allowTouchMove={false}
            >
              {showDialog === true ? (
                <SwiperSlide>
                  <div className="px-2">
                    <p className="md:text-base text-sm lg:text-lg font-bold leading-2 text-[#4D4D4D] py-4">
                      L'élève que vous essayez d'ajouter posséde déjà un compte
                      sur Teach'r. Pour qu'il puisse tirer parti de l'avance de
                      crédit d'impôt immédiatement, veuillez lui communiquer le
                      code ci-dessous afin qu'il puisse activer cette option
                      depuis son espace Teach'r. Si vous préférez, vous pouvez
                      également choisir de lui envoyer un email{" "}
                      <span
                        onClick={sendTeachrCode}
                        className="text-radio underline cursor-pointer"
                      >
                        cliquez ici.
                      </span>
                    </p>
                    <div
                      className={`${
                        isCopy
                          ? "border-first"
                          : "border-[rgba(183,183,183,0.64)]"
                      } p-2 rounded-md border flex items-center justify-between`}
                    >
                      <p
                        className={`${
                          isCopy ? "text-first" : "text-[808080#]"
                        } lg:text-lg text-sm`}
                      >
                        {teachrCode ? teachrCode : null}
                      </p>
                      <CopyToClipboard
                        text={teachrCode ? teachrCode : ""}
                        onCopy={() => {
                          setIsCopy(true);
                        }}
                      >
                        <span className="cursor-pointer">
                          <svg
                            width="32"
                            height="40"
                            viewBox="0 0 32 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_701_3)">
                              <path
                                d="M9.99999 26C9.40703 25.9995 8.82752 25.8233 8.33466 25.4936C7.84179 25.1639 7.45768 24.6956 7.23084 24.1477C7.00399 23.5999 6.94459 22.9971 7.06013 22.4155C7.17566 21.8339 7.46096 21.2996 7.87999 20.88L8.87999 19.88C9.45111 19.3348 10.2104 19.0305 11 19.0305C11.7896 19.0305 12.5489 19.3348 13.12 19.88C13.6818 20.4425 13.9973 21.205 13.9973 22C13.9973 22.795 13.6818 23.5575 13.12 24.12L12.12 25.12C11.5579 25.6828 10.7954 25.9993 9.99999 26ZM11 21C10.8684 20.9993 10.7379 21.0245 10.6161 21.0743C10.4942 21.124 10.3834 21.1974 10.29 21.29L9.28999 22.29C9.10374 22.4774 8.99919 22.7309 8.99919 22.995C8.99919 23.2592 9.10374 23.5127 9.28999 23.7C9.47735 23.8863 9.7308 23.9908 9.99499 23.9908C10.2592 23.9908 10.5126 23.8863 10.7 23.7L11.7 22.7C11.837 22.5603 11.93 22.3834 11.9675 22.1912C12.0049 21.9991 11.9851 21.8002 11.9106 21.6192C11.8361 21.4382 11.7101 21.283 11.5482 21.173C11.3863 21.0629 11.1957 21.0028 11 21Z"
                                fill={isCopy ? "#0054A3" : "#808080"}
                              />
                              <path
                                d="M15 21C14.407 20.9995 13.8275 20.8233 13.3347 20.4936C12.8418 20.1639 12.4577 19.6956 12.2308 19.1477C12.004 18.5999 11.9446 17.9971 12.0601 17.4155C12.1757 16.8339 12.461 16.2996 12.88 15.88L13.88 14.88C14.4511 14.3348 15.2104 14.0305 16 14.0305C16.7896 14.0305 17.5489 14.3348 18.12 14.88C18.6818 15.4425 18.9973 16.205 18.9973 17C18.9973 17.795 18.6818 18.5575 18.12 19.12L17.12 20.12C16.5579 20.6828 15.7954 20.9993 15 21ZM16 16C15.8684 15.9993 15.7379 16.0245 15.6161 16.0743C15.4942 16.124 15.3834 16.1974 15.29 16.29L14.29 17.29C14.1037 17.4774 13.9992 17.7309 13.9992 17.995C13.9992 18.2592 14.1037 18.5127 14.29 18.7C14.4773 18.8863 14.7308 18.9908 14.995 18.9908C15.2592 18.9908 15.5126 18.8863 15.7 18.7L16.7 17.7C16.837 17.5603 16.93 17.3834 16.9675 17.1912C17.0049 16.9991 16.9851 16.8002 16.9106 16.6192C16.8361 16.4382 16.7101 16.283 16.5482 16.173C16.3863 16.0629 16.1957 16.0028 16 16Z"
                                fill={isCopy ? "#0054A3" : "#808080"}
                              />
                              <path
                                d="M12 22C11.8014 22.0012 11.6069 21.9432 11.4414 21.8333C11.2759 21.7235 11.1468 21.5669 11.0706 21.3835C10.9944 21.2001 10.9746 20.9981 11.0137 20.8033C11.0528 20.6086 11.149 20.4299 11.29 20.29L13.29 18.29C13.4813 18.1262 13.7274 18.0406 13.979 18.0503C14.2307 18.06 14.4695 18.1644 14.6475 18.3425C14.8256 18.5205 14.93 18.7593 14.9397 19.011C14.9494 19.2626 14.8638 19.5087 14.7 19.7L12.7 21.7C12.5154 21.8884 12.2638 21.9963 12 22Z"
                                fill={isCopy ? "#0054A3" : "#808080"}
                              />
                              <path
                                d="M23 12H19C18.2043 12 17.4413 11.6839 16.8787 11.1213C16.3161 10.5587 16 9.79561 16 8.99996V4.99996C15.9988 4.80133 16.0569 4.60686 16.1667 4.44134C16.2765 4.27582 16.4331 4.14675 16.6165 4.07059C16.7999 3.99442 17.0019 3.97461 17.1967 4.01368C17.3914 4.05275 17.5701 4.14893 17.71 4.28996L23.71 10.29C23.851 10.4298 23.9472 10.6085 23.9863 10.8033C24.0253 10.998 24.0055 11.2 23.9294 11.3834C23.8532 11.5669 23.7241 11.7235 23.5586 11.8333C23.3931 11.9431 23.1986 12.0011 23 12ZM18 7.40996V8.99996C18 9.26517 18.1053 9.51953 18.2929 9.70706C18.4804 9.8946 18.7348 9.99996 19 9.99996H20.59L18 7.40996Z"
                                fill={isCopy ? "#0054A3" : "#808080"}
                              />
                              <path
                                d="M21 32H5C4.20435 32 3.44129 31.6839 2.87868 31.1213C2.31607 30.5587 2 29.7957 2 29V7.00002C2 6.20437 2.31607 5.44131 2.87868 4.8787C3.44129 4.31609 4.20435 4.00002 5 4.00002H17C17.1316 3.99926 17.2621 4.02448 17.3839 4.07425C17.5057 4.12401 17.6166 4.19734 17.71 4.29002L23.71 10.29C23.8027 10.3835 23.876 10.4943 23.9258 10.6161C23.9755 10.7379 24.0008 10.8684 24 11V29C24 29.7957 23.6839 30.5587 23.1213 31.1213C22.5587 31.6839 21.7956 32 21 32ZM5 6.00002C4.73478 6.00002 4.48043 6.10537 4.29289 6.29291C4.10536 6.48045 4 6.7348 4 7.00002V29C4 29.2652 4.10536 29.5196 4.29289 29.7071C4.48043 29.8947 4.73478 30 5 30H21C21.2652 30 21.5196 29.8947 21.7071 29.7071C21.8946 29.5196 22 29.2652 22 29V11.41L16.59 6.00002H5Z"
                                fill={isCopy ? "#0054A3" : "#808080"}
                              />
                              <path
                                d="M29 7.99996H25C24.2043 7.99996 23.4413 7.68389 22.8787 7.12128C22.3161 6.55867 22 5.79561 22 4.99996V0.999956C21.9988 0.801332 22.0569 0.606863 22.1667 0.441343C22.2765 0.275823 22.4331 0.146752 22.6165 0.0705855C22.7999 -0.00558127 23.0019 -0.0253928 23.1967 0.0136769C23.3914 0.0527466 23.5701 0.148927 23.71 0.289956L29.71 6.28996C29.851 6.42983 29.9472 6.60853 29.9863 6.80328C30.0253 6.99802 30.0055 7.19999 29.9294 7.38344C29.8532 7.56688 29.7241 7.72349 29.5586 7.83329C29.3931 7.94309 29.1986 8.00111 29 7.99996ZM24 3.40996V4.99996C24 5.26517 24.1053 5.51953 24.2929 5.70706C24.4804 5.8946 24.7348 5.99996 25 5.99996H26.59L24 3.40996Z"
                                fill={isCopy ? "#0054A3" : "#808080"}
                              />
                              <path
                                d="M27 28H23C22.7348 28 22.4804 27.8947 22.2929 27.7071C22.1054 27.5196 22 27.2652 22 27C22 26.7348 22.1054 26.4804 22.2929 26.2929C22.4804 26.1054 22.7348 26 23 26H27C27.2652 26 27.5196 25.8947 27.7071 25.7071C27.8946 25.5196 28 25.2652 28 25V7.41002L22.59 2.00002H11C10.7348 2.00002 10.4804 2.10537 10.2929 2.29291C10.1054 2.48045 10 2.7348 10 3.00002V5.00002C10 5.26523 9.89464 5.51959 9.70711 5.70712C9.51957 5.89466 9.26522 6.00002 9 6.00002C8.73478 6.00002 8.48043 5.89466 8.29289 5.70712C8.10536 5.51959 8 5.26523 8 5.00002V3.00002C8 2.20437 8.31607 1.44131 8.87868 0.878696C9.44129 0.316087 10.2044 1.67143e-05 11 1.67143e-05H23C23.1316 -0.000744179 23.2621 0.0244809 23.3839 0.0742455C23.5057 0.12401 23.6166 0.197335 23.71 0.290017L29.71 6.29002C29.8027 6.38346 29.876 6.49427 29.9258 6.61611C29.9755 6.73795 30.0008 6.86841 30 7.00002V25C30 25.7957 29.6839 26.5587 29.1213 27.1213C28.5587 27.6839 27.7956 28 27 28Z"
                                fill={isCopy ? "#0054A3" : "#808080"}
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_701_3">
                                <rect width="32" height="40" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </CopyToClipboard>
                    </div>
                    <div className="flex gap-x-4 mx-auto pt-6 justify-between py-4">
                      <Button
                        onClick={() => handleClose()}
                        className="block bg-dark_blue font-extrabold text-center rounded-full md:px-14  py-1 text-white  border-solid border-[1px] border-first    w-full"
                      >
                        Fermer
                      </Button>
                    </div>
                  </div>
                </SwiperSlide>
              ) : showDialog2 ? (
                <>
                  <SwiperSlide>
                    <div className="px-2">
                      <p className="md:text-base text-sm lg:text-lg font-bold leading-2 text-[#4D4D4D]">
                        Votre questionnaire à bien été envoyé à votre client.{" "}
                        Une fois validé, le statut de votre élève changera
                        directement sur le tableau de bord. <br />
                        <br /> Un lien exclusif a été créé pour votre élève. Si
                        cela vous convient, vous pouvez le copier et le partager
                        avec lui. <br />
                        <br />
                      </p>
                      <div
                        className={`${
                          isCopy
                            ? "border-first"
                            : "border-[rgba(183,183,183,0.64)]"
                        } p-2 rounded-md border flex items-center  justify-between`}
                      >
                        <p
                          className={`${
                            isCopy ? "text-first" : "text-[808080#]"
                          } text-sm w-5/6 whitespace-normal truncate`}
                        >
                          {link ? link : null}
                        </p>
                        <CopyToClipboard
                          text={link ? link : ""}
                          onCopy={() => {
                            setIsCopy(true);
                          }}
                        >
                          <span className="cursor-pointer">
                            <svg
                              width="32"
                              height="40"
                              viewBox="0 0 32 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_701_3)">
                                <path
                                  d="M9.99999 26C9.40703 25.9995 8.82752 25.8233 8.33466 25.4936C7.84179 25.1639 7.45768 24.6956 7.23084 24.1477C7.00399 23.5999 6.94459 22.9971 7.06013 22.4155C7.17566 21.8339 7.46096 21.2996 7.87999 20.88L8.87999 19.88C9.45111 19.3348 10.2104 19.0305 11 19.0305C11.7896 19.0305 12.5489 19.3348 13.12 19.88C13.6818 20.4425 13.9973 21.205 13.9973 22C13.9973 22.795 13.6818 23.5575 13.12 24.12L12.12 25.12C11.5579 25.6828 10.7954 25.9993 9.99999 26ZM11 21C10.8684 20.9993 10.7379 21.0245 10.6161 21.0743C10.4942 21.124 10.3834 21.1974 10.29 21.29L9.28999 22.29C9.10374 22.4774 8.99919 22.7309 8.99919 22.995C8.99919 23.2592 9.10374 23.5127 9.28999 23.7C9.47735 23.8863 9.7308 23.9908 9.99499 23.9908C10.2592 23.9908 10.5126 23.8863 10.7 23.7L11.7 22.7C11.837 22.5603 11.93 22.3834 11.9675 22.1912C12.0049 21.9991 11.9851 21.8002 11.9106 21.6192C11.8361 21.4382 11.7101 21.283 11.5482 21.173C11.3863 21.0629 11.1957 21.0028 11 21Z"
                                  fill={isCopy ? "#0054A3" : "#808080"}
                                />
                                <path
                                  d="M15 21C14.407 20.9995 13.8275 20.8233 13.3347 20.4936C12.8418 20.1639 12.4577 19.6956 12.2308 19.1477C12.004 18.5999 11.9446 17.9971 12.0601 17.4155C12.1757 16.8339 12.461 16.2996 12.88 15.88L13.88 14.88C14.4511 14.3348 15.2104 14.0305 16 14.0305C16.7896 14.0305 17.5489 14.3348 18.12 14.88C18.6818 15.4425 18.9973 16.205 18.9973 17C18.9973 17.795 18.6818 18.5575 18.12 19.12L17.12 20.12C16.5579 20.6828 15.7954 20.9993 15 21ZM16 16C15.8684 15.9993 15.7379 16.0245 15.6161 16.0743C15.4942 16.124 15.3834 16.1974 15.29 16.29L14.29 17.29C14.1037 17.4774 13.9992 17.7309 13.9992 17.995C13.9992 18.2592 14.1037 18.5127 14.29 18.7C14.4773 18.8863 14.7308 18.9908 14.995 18.9908C15.2592 18.9908 15.5126 18.8863 15.7 18.7L16.7 17.7C16.837 17.5603 16.93 17.3834 16.9675 17.1912C17.0049 16.9991 16.9851 16.8002 16.9106 16.6192C16.8361 16.4382 16.7101 16.283 16.5482 16.173C16.3863 16.0629 16.1957 16.0028 16 16Z"
                                  fill={isCopy ? "#0054A3" : "#808080"}
                                />
                                <path
                                  d="M12 22C11.8014 22.0012 11.6069 21.9432 11.4414 21.8333C11.2759 21.7235 11.1468 21.5669 11.0706 21.3835C10.9944 21.2001 10.9746 20.9981 11.0137 20.8033C11.0528 20.6086 11.149 20.4299 11.29 20.29L13.29 18.29C13.4813 18.1262 13.7274 18.0406 13.979 18.0503C14.2307 18.06 14.4695 18.1644 14.6475 18.3425C14.8256 18.5205 14.93 18.7593 14.9397 19.011C14.9494 19.2626 14.8638 19.5087 14.7 19.7L12.7 21.7C12.5154 21.8884 12.2638 21.9963 12 22Z"
                                  fill={isCopy ? "#0054A3" : "#808080"}
                                />
                                <path
                                  d="M23 12H19C18.2043 12 17.4413 11.6839 16.8787 11.1213C16.3161 10.5587 16 9.79561 16 8.99996V4.99996C15.9988 4.80133 16.0569 4.60686 16.1667 4.44134C16.2765 4.27582 16.4331 4.14675 16.6165 4.07059C16.7999 3.99442 17.0019 3.97461 17.1967 4.01368C17.3914 4.05275 17.5701 4.14893 17.71 4.28996L23.71 10.29C23.851 10.4298 23.9472 10.6085 23.9863 10.8033C24.0253 10.998 24.0055 11.2 23.9294 11.3834C23.8532 11.5669 23.7241 11.7235 23.5586 11.8333C23.3931 11.9431 23.1986 12.0011 23 12ZM18 7.40996V8.99996C18 9.26517 18.1053 9.51953 18.2929 9.70706C18.4804 9.8946 18.7348 9.99996 19 9.99996H20.59L18 7.40996Z"
                                  fill={isCopy ? "#0054A3" : "#808080"}
                                />
                                <path
                                  d="M21 32H5C4.20435 32 3.44129 31.6839 2.87868 31.1213C2.31607 30.5587 2 29.7957 2 29V7.00002C2 6.20437 2.31607 5.44131 2.87868 4.8787C3.44129 4.31609 4.20435 4.00002 5 4.00002H17C17.1316 3.99926 17.2621 4.02448 17.3839 4.07425C17.5057 4.12401 17.6166 4.19734 17.71 4.29002L23.71 10.29C23.8027 10.3835 23.876 10.4943 23.9258 10.6161C23.9755 10.7379 24.0008 10.8684 24 11V29C24 29.7957 23.6839 30.5587 23.1213 31.1213C22.5587 31.6839 21.7956 32 21 32ZM5 6.00002C4.73478 6.00002 4.48043 6.10537 4.29289 6.29291C4.10536 6.48045 4 6.7348 4 7.00002V29C4 29.2652 4.10536 29.5196 4.29289 29.7071C4.48043 29.8947 4.73478 30 5 30H21C21.2652 30 21.5196 29.8947 21.7071 29.7071C21.8946 29.5196 22 29.2652 22 29V11.41L16.59 6.00002H5Z"
                                  fill={isCopy ? "#0054A3" : "#808080"}
                                />
                                <path
                                  d="M29 7.99996H25C24.2043 7.99996 23.4413 7.68389 22.8787 7.12128C22.3161 6.55867 22 5.79561 22 4.99996V0.999956C21.9988 0.801332 22.0569 0.606863 22.1667 0.441343C22.2765 0.275823 22.4331 0.146752 22.6165 0.0705855C22.7999 -0.00558127 23.0019 -0.0253928 23.1967 0.0136769C23.3914 0.0527466 23.5701 0.148927 23.71 0.289956L29.71 6.28996C29.851 6.42983 29.9472 6.60853 29.9863 6.80328C30.0253 6.99802 30.0055 7.19999 29.9294 7.38344C29.8532 7.56688 29.7241 7.72349 29.5586 7.83329C29.3931 7.94309 29.1986 8.00111 29 7.99996ZM24 3.40996V4.99996C24 5.26517 24.1053 5.51953 24.2929 5.70706C24.4804 5.8946 24.7348 5.99996 25 5.99996H26.59L24 3.40996Z"
                                  fill={isCopy ? "#0054A3" : "#808080"}
                                />
                                <path
                                  d="M27 28H23C22.7348 28 22.4804 27.8947 22.2929 27.7071C22.1054 27.5196 22 27.2652 22 27C22 26.7348 22.1054 26.4804 22.2929 26.2929C22.4804 26.1054 22.7348 26 23 26H27C27.2652 26 27.5196 25.8947 27.7071 25.7071C27.8946 25.5196 28 25.2652 28 25V7.41002L22.59 2.00002H11C10.7348 2.00002 10.4804 2.10537 10.2929 2.29291C10.1054 2.48045 10 2.7348 10 3.00002V5.00002C10 5.26523 9.89464 5.51959 9.70711 5.70712C9.51957 5.89466 9.26522 6.00002 9 6.00002C8.73478 6.00002 8.48043 5.89466 8.29289 5.70712C8.10536 5.51959 8 5.26523 8 5.00002V3.00002C8 2.20437 8.31607 1.44131 8.87868 0.878696C9.44129 0.316087 10.2044 1.67143e-05 11 1.67143e-05H23C23.1316 -0.000744179 23.2621 0.0244809 23.3839 0.0742455C23.5057 0.12401 23.6166 0.197335 23.71 0.290017L29.71 6.29002C29.8027 6.38346 29.876 6.49427 29.9258 6.61611C29.9755 6.73795 30.0008 6.86841 30 7.00002V25C30 25.7957 29.6839 26.5587 29.1213 27.1213C28.5587 27.6839 27.7956 28 27 28Z"
                                  fill={isCopy ? "#0054A3" : "#808080"}
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_701_3">
                                  <rect width="32" height="40" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                        </CopyToClipboard>
                      </div>
                      <div className="flex gap-x-4 mx-auto pt-6 justify-between py-4">
                        <Button
                          onClick={() => handleClose()}
                          className="block bg-dark_blue font-extrabold text-center rounded-full md:px-14  py-1 text-white  border-solid border-[1px] border-first    w-full"
                        >
                          Fermer
                        </Button>
                      </div>
                    </div>
                  </SwiperSlide>
                </>
              ) : (
                <>
                  {childs?.length === 0 && (
                    <SwiperSlide>
                      <div className="px-2">
                        <p className="md:text-base text-sm lg:text-lg font-bold leading-2 text-[#4D4D4D] py-4">
                          Pour inscrire votre élève, vous avez uniquement besoin
                          de son numéro de téléphone et de son adresse e-mail.
                          Si l'élève prend en charge le financement de ses
                          cours, merci de saisir ses informations personnelles.
                          Dans le cas contraire, veuillez entrer les coordonnées
                          de ses parents ou tuteurs légaux.
                          <br /> Si vous possédez des informations
                          supplémentaires,{" "}
                          <span
                            onClick={() => navigate("/teachr/dashboard/setup")}
                            className="text-radio underline cursor-pointer"
                          >
                            cliquez sur ce lien
                          </span>{" "}
                          pour pré-remplir les données au nom de votre élève,
                          qui recevra alors une notification par e-mail.
                        </p>
                        <div className="flex gap-x-4 mx-auto pt-4 justify-between py-2">
                          <Button
                            onClick={() => handleClose()}
                            className="block bg-white font-extrabold text-center rounded-full md:px-14  py-1 text-first border-2  border-solid border-first     w-full"
                          >
                            Fermer
                          </Button>
                          <Button
                            id="change-chart"
                            className="animate__animated next-slide disabled:opacity-40 block bg-dark_blue font-extrabold text-center rounded-full md:px-14  py-1 text-white  border-solid border-[1px] border-first    w-full"
                          >
                            Suivant
                          </Button>
                        </div>
                      </div>
                    </SwiperSlide>
                  )}
                  <SwiperSlide>
                    <div className="px-2">
                      <p className="md:text-base text-sm lg:text-lg font-bold leading-2 text-[#4D4D4D] py-2">
                        Veuillez remplir les informations suivantes
                      </p>
                      <div
                        className={`${
                          errors.mail || errors.telephone ? "pt-5" : "pt-0"
                        } grid md:grid-cols-2 md:gap-4 gap-8`}
                      >
                        <div className="relative w-full">
                          <p
                            className={`${
                              errors.mail ? "absolute" : "hidden"
                            } text-third text-base md:text-base  -top-7`}
                          >
                            {validateEmail(email).message}
                          </p>
                          <div className="relative px-4 py-1 rounded-lg bg-input-color shadow-dashboard-setup-input h-max">
                            <label className="block lg:text-base">
                              Adresse email{" "}
                              <span className="text-third">{"*"}</span>
                            </label>
                            <input
                              className={` w-full lg:text-base bg-transparent placeholder:text-grey`}
                              type="text"
                              value={email}
                              onChange={(e) => setEmail((e.target.value).toLowerCase())}
                              placeholder="Ex: mail@mail.com"
                            />
                          </div>
                        </div>
                        <div className="w-full ">
                          <div className="relative w-full">
                            <p
                              className={`${
                                errors.telephone ? "absolute" : "hidden"
                              } text-third text-base md:text-base -top-7`}
                            >
                              {validatePhoneNumber(phone)}
                            </p>

                            <div className="relative px-4 py-1 rounded-lg bg-input-color shadow-dashboard-setup-input h-max">
                              <label className="block lg:text-base">
                                Telephone{" "}
                                <span className="text-third">{"*"}</span>
                              </label>
                              <input
                                className={` w-full lg:text-base bg-transparent placeholder:text-grey`}
                                type="text"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder="Ex: 06 XX XX XX XX"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="md:text-base text-sm lg:text-lg font-bold leading-2 text-[#4D4D4D] py-2">
                        Informations optionnelles supplémentaires
                      </p>
                      <div className={`grid md:grid-cols-2 md:gap-4 gap-8`}>
                        <div className="relative w-full">
                          <div className="relative px-4 py-1 rounded-lg bg-input-color shadow-dashboard-setup-input h-max">
                            <label className="block lg:text-base">
                              Prénom de l'élève
                            </label>
                            <input
                              className={` w-full lg:text-base bg-transparent placeholder:text-grey`}
                              type="text"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              placeholder="Ex: john"
                            />
                          </div>
                        </div>
                        <div className="w-full ">
                          <div className="relative w-full">
                            <div className="relative px-4 py-1 rounded-lg bg-input-color shadow-dashboard-setup-input h-max">
                              <label className="block lg:text-base">
                                Nom de famille
                              </label>
                              <input
                                className={` w-full lg:text-base bg-transparent placeholder:text-grey`}
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Ex: DUPONT"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="md:text-base text-sm lg:text-lg font-bold leading-2 text-[#4D4D4D] pt-4 ">
                        Si vous souhaitez rajouter d'autres informations,
                        <span
                          onClick={() => navigate("/teachr/dashboard/setup")}
                          className="text-radio underline cursor-pointer"
                        >
                          {" "}
                          cliquez sur ce lien{" "}
                        </span>
                      </p>
                      <div className="flex gap-x-4 mx-auto pt-10 justify-between py-2">
                        {childs?.length === 0 ? (
                          <Button
                            id="change-chart"
                            className="animate__animated prev-slide disabled:opacity-40 block bg-white font-extrabold text-center rounded-full md:px-14 py-1 text-first  border-solid border-2 border-first    w-full"
                          >
                            Précédent
                          </Button>
                        ) : (
                          <Button
                            onClick={() => handleClose()}
                            className="block bg-white font-extrabold text-center rounded-full md:px-14  py-1 text-first border-2  border-solid border-first     w-full"
                          >
                            Fermer
                          </Button>
                        )}
                        {showDialog ? (
                          <Button
                            id="change-chart"
                            className="animate__animated next-slide disabled:opacity-40 block bg-dark_blue font-extrabold text-center rounded-full md:px-14 py-1 text-white  border-solid border-[1px] border-first    w-full"
                          >
                            Suivant
                          </Button>
                        ) : (
                          <Button
                            onClick={() => handleAddStudent()}
                            className={`${
                              loading
                                ? "bg-gray-300 border-gray-300"
                                : "bg-dark_blue border-first"
                            } block font-extrabold text-center rounded-full md:px-14 py-1 text-white border-solid border-[1px  w-full`}
                          >
                            {loading ? "Chargement..." : "Envoyer"}
                          </Button>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                </>
              )}
            </Swiper>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
const AddStudentPage = () => {
  const radioGroupRef = useRef<HTMLElement>(null);
  const [value, setValue] = useState("");
  const [clientTiersPrestation, setClientTiersPrestation] = useState<any>();
  const [childs, setChilds] = useState<any>();
  const [teachr, setTeachr] = useState<any>();
  const [isDelete, setIsDelete] = useState(false);
  const [relance, setRelance] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isDelete2, setIsDelete2] = useState(false);
  const [component, setComponent] = useState(1);
  const [isLoad2, setIsLoad2] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [currentPage3, setCurrentPage3] = useState(1);
  const [dataPerPage] = useState(8);
  const [dataPerPage2] = useState(8);
  const [dataPerPage3] = useState(8);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dataProfile, setDataProfile] = useState<dataProfileModel>();
  const [dataArchive, setDataArchive] = useState<btnArchiveModel>();
  const [dataDelete, setDataDelete] = useState<btnDeleteModel>();
  const [dataDeclare, setDataDeclare] = useState<dataDeclareModel>();
  const [sort, setSort] = useState({
    sortDuration : false,
    sortPrice : false,
    sortName : false,
    sortDurationDown : false,
    sortPriceDown : false,
    sortNameDown : false,
  });
  
  // const isTrue= false
  const open = Boolean(anchorEl);
  const handleSetDataProfile = (val: any) => {
    setDataProfile(val);
  };
  const handleSetDataArchive = (val: any) => {
    setDataArchive(val);
  };
  const handleSetDataDelete = (val: any) => {
    setDataDelete(val);
  };
  const handleSetDataDeclare = (val: any) => {
    setDataDeclare(val);
  };
  const handleValueChange = () => {
    setIsAction(true);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const filteredCourses2 = clientTiersPrestation?.filter((child: any) => {
    return child.is_archive;
  });
  const filteredCourses3 = clientTiersPrestation?.sort(function (a: any, b: any) {
    const priceA = a?.is_teachr_contact ? a?.amount : a?.price_per_hour * (a?.duration/2)
    const priceB = b?.is_teachr_contact ? b?.amount : b?.price_per_hour * (b?.duration/2)
    if(sort.sortDuration){
      return b?.duration - a?.duration;
    } else if(sort.sortPrice){
      return priceB - priceA
    } else if(sort.sortName) {
      return a?.child_first_name.localeCompare(b?.child_first_name)
    } else if(sort.sortDurationDown){
      return a?.duration - b?.duration;
    } else if(sort.sortPriceDown){
      return priceA - priceB
    } else if(sort.sortNameDown) {
      return b?.child_first_name.localeCompare(a?.child_first_name)
    }
  }).filter((child: any) => {
    return !child.is_archive;
  })
  const sortedCourses = childs?.sort(function (a: any, b: any) {
    const date1: any = moment(a.createdAt.date).toDate();
    const date2: any = moment(b.createdAt.date).toDate();
    return date2 - date1;
  });
  // Pagination
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfLastData2 = currentPage2 * dataPerPage2;
  const indexOfLastData3 = currentPage3 * dataPerPage3;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const indexOfFirstData2 = indexOfLastData2 - dataPerPage2;
  const indexOfFirstData3 = indexOfLastData3 - dataPerPage3;
  const currentData = sortedCourses?.slice(indexOfFirstData, indexOfLastData);
  const currentData2 = filteredCourses2?.slice(
    indexOfFirstData2,
    indexOfLastData2
  );
  const currentData3 = filteredCourses3?.slice(
    indexOfFirstData3,
    indexOfLastData3
  );
  const handleLoadDelete = () => {
    setIsLoad(true);
  };
  const displayAddStudent = useDisplayAddStudent(childs, handleLoadDelete);
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const paginate2 = (pageNumber: number) => {
    setCurrentPage2(pageNumber);
  };
  const paginate3 = (pageNumber: number) => {
    setCurrentPage3(pageNumber);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  const handleDeleteTrue = (val: boolean) => {
    setIsDelete(val);
  };
  const handleRelance = (val: boolean) => {
    setRelance(val);
  };
  const handleDeleteTrue2 = (val: boolean) => {
    setIsDelete2(val);
  };
  const handleLoadDelete2 = () => {
    setIsLoad2(true);
  };
  useEffect(() => {
    TeachrRepository.getCurrentTeachr().then((response) => {
      if (response && response.id) {
        setTeachr(response);
      }
    });
  }, []);
  useEffect(() => {
    if (teachr?.id || sort?.sortDuration || sort?.sortPrice || sort?.sortNameDown || sort?.sortDurationDown || sort?.sortPriceDown || sort?.sortNameDown  ) {
      TeachrContactRepository.fetchClientByTeachr(teachr?.id).then((res) => {
        setChilds(res);
      });
      TeachrRepository.get_client_by_teacher_favorite(teachr?.id).then(
        (client: any) => {
          setClientTiersPrestation(client.clients);
        }
      );
    }
  }, [teachr?.id, sort?.sortDuration , sort?.sortPrice , sort?.sortName , sort?.sortDurationDown , sort?.sortPriceDown , sort?.sortNameDown ]);
  useEffect(() => {
    setTimeout(() => {
      if (isLoad) {
        if (teachr?.id) {
          TeachrContactRepository.fetchClientByTeachr(teachr?.id).then(
            (res) => {
              setChilds(res);
            }
          );
          setIsLoad(false);
        }
      }
    }, 200);
  }, [isLoad, teachr?.id]);
  useEffect(() => {
    setTimeout(() => {
      if (isLoad2) {
        if (teachr?.id || sort?.sortDuration || sort?.sortPrice || sort?.sortNameDown || sort?.sortDurationDown || sort?.sortPriceDown || sort?.sortNameDown ) {
          TeachrRepository.get_client_by_teacher_favorite(teachr?.id).then(
            (client: any) => {
              setClientTiersPrestation(client.clients);
            }
          );
        }
        setIsLoad2(false);
      }
    }, 200);
  }, [isLoad2, teachr?.id, sort?.sortDuration , sort?.sortPrice , sort?.sortName , sort?.sortDurationDown , sort?.sortPriceDown , sort?.sortNameDown ]);
  const displayChilds = (comp: number) => {
    switch (comp) {
      case 1:
        return (
          <table className="text-left w-full relative h-[90%] lg:px-4 /p-3">
            <thead className="flex/lg:text-lg md:text-base text-sm text-[#4d4d4d] h-[10%] w-full border-b-2 border-first">
              <tr className="flex w-full text-first">
                <th className="md:py-2 md:px-2 md:w-2/5 w-2/4">
                  Prénom et Nom
                </th>
                <th className="py-2 md:w-2/5 w-1/4 ">Statut</th>
                <th className="py-2 md:px-5 md:w-1/5 w-1/4  text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="overflow-y-scroll w-full scroll py-2 h-[100%] absolute">
              {currentData?.map((child: any, key: any) => (
                <ComponentChildStudent
                  child={child}
                  key={key}
                  isDelete={isDelete}
                  relance={relance}
                  handleLoadDelete={handleLoadDelete}
                  handleDeleteTrue={handleDeleteTrue}
                  handleRelance={handleRelance}
                  isLoad={isLoad}
                  setIsLoad={setIsLoad}
                />
              ))}
            </tbody>
          </table>
        );
      case 2:
        return (
          <>
            {clientTiersPrestation?.filter((child: any) => child.is_archive)
              .length === 0 ? (
              <div className="text-[#4d4d4d] relative h-[90%] text-center lg:text-lg text-xl px-4 md:mt-10 font-bold">
                <p>
                  Vous n'avez pas encore archivé d'élève.
                  <br /> Retrouvez ici vos élèves validés que vous avez archivé.
                </p>
              </div>
            ) : (
              <table className="text-left w-full  relative h-[90%] p-4">
                <thead className="flex/lg:text-lg md:text-base text-sm text-[#4d4d4d] h-[10%] w-full border-b-2 border-first">
                  <tr className="flex w-full text-first">
                    <th className="md:py-2  md:px-4 px-1 md:w-3/5 w-2/4">
                      Prénom et Nom
                    </th>
                    <th className="py-2  md:w-1/5 w-1/4">Statut</th>
                    <th className="py-2 md:w-1/5 w-1/4 text-center ">Action</th>
                  </tr>
                </thead>
                <tbody className="overflow-y-scroll w-full scroll h-[100%] absolute">
                  {currentData2?.map((child: any, key: any) => (
                    <ComponentArchive
                      child={child}
                      key={key}
                      isDelete2={isDelete2}
                      handleLoadDelete2={handleLoadDelete2}
                      isLoad2={isLoad2}
                      setIsLoad2={setIsLoad2}
                      handleDeleteTrue2={handleDeleteTrue2}
                      onValueChange2={setIsAction}
                    />
                  ))}
                </tbody>
              </table>
            )}
          </>
        );
    }
  };
  const displayButtonAddStudent = () => {
    return (
      <div className="py-2 gap-x-2 items-center flex h-[10%]">
        <div className="border-b-2 flex items-center gap-x-2 rounded-lg border-first">
          <img
            src={add}
            alt="icone"
            className="lg:w-6 w-5 lg:mt-0 md:mt-2 text-dark_blue"
          />
          {displayAddStudent}
        </div>
      </div>
    );
  };
  const handleAction = () => {
    setIsAction(true);
  };

  // const handleRefresh = () => {
  //   setIsLoad2(true);
  //   clientTiersPrestation
  //     ?.filter((child: any) => !child.is_archive)
  //     .map((child: any, key: any) => {
  //       ClientRepository.refreshStatusTdp(child?.client_id)
  //         .then((res) => {
  //           // handleLoadDelete2();
  //         })
  //         .catch((error) => {
  //           setIsLoad2(false);
  //           // dispatch({
  //           //   type: "SHOW_ERROR",
  //           //   payload: JSON.parse(error.message).translate,
  //           // });
  //           console.warn(JSON.parse(error.message));
  //         });

  //     })
  //   // setIsLoad2(false);
  // };
  return (
    <>
      <div className="h-screen pb-20 overflow-hidden bg-[#F4FAFF]">
        <Navbar index={2} />
        <div className="lg:h-[100%] h-[100%] overflow-y-auto md:px-8 px-4">
          {displayButtonAddStudent()}
          <div className="grid md:grid-cols-9 md:gap-4 gap-y-4 h-[90%]">
            <div className="md:h-[100%] h-[700px] md:col-span-4 ">
              <div
                className={`${
                  !childs
                    ? ""
                    : childs?.length === 0
                    ? "invisible"
                    : "md:flex items-center justify-between"
                } h-[10%] lg:text-lg md:text-base text-sm `}
              >
                {!childs ? (
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={"100%"}
                    width="100%"
                  />
                ) : (
                  <div className="space-y-2 md:space-y-0 w-full md:flex ">
                    <h1 className={`font-extrabold text-dark_blue md:w-1/2 w-full lg:text-lg md:text-base text-sm`}>
                      Mes élèves
                    </h1>
                    <div className="cursor-pointer rounded-lg border-b-[2px] border-first md:flex md:w-1/2 w-full md:mb-0 mb-2">
                      <button
                        className={
                          component === 2
                            ? "bg-[#FFFFFF] text-first rounded-lg cursor-pointer lg:text-lg md:text-base text-sm w-1/2 md:py-0 py-1"
                            : "bg-[#c7e4ff] text-first rounded-lg cursor-pointer lg:text-lg md:text-base text-sm w-1/2 md:py-0 py-1"
                        }
                        onClick={() => setComponent(1)}
                      >
                        <p className=" font-bold truncate">En cours</p>
                      </button>
                      <button
                        className={
                          component === 1
                            ? "bg-[#FFFFFF] text-first rounded-lg cursor-pointer lg:text-lg md:text-base text-sm w-1/2 md:py-0 py-1"
                            : "bg-[#c7e4ff] text-first rounded-lg cursor-pointer lg:text-lg md:text-base text-sm w-1/2 md:py-0 py-1"
                        }
                        onClick={() => setComponent(2)}
                      >
                        <p className=" font-bold">Archivés</p>
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="shadow-dashboard-setup-intro-form bg-white rounded-lg border-[2px] border-first h-[90%]">
                <div className="h-[89%] md:pt-0 pt-4">
                  {!childs ? (
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      height={"100%"}
                      width="100%"
                    />
                  ) : childs?.length === 0 ? (
                    <div className="text-[#4d4d4d] text-center h-[400px] px-4 font-bold flex flex-col items-center justify-center /gap-y-2">
                      <p className="text-center xl:text-xl lg:text-lg md:text-base text-sm">
                        Aucun élève n'est actuellement en cours d'ajout, vous
                        pouvez ajouter votre prochain élève en cliquant sur
                      </p>
                      {displayAddStudent}
                      <div className=" md:mt-12 mx-auto mt-2 md:w-1/2">
                        <img
                          src={add_srudent}
                          alt="add_srudent"
                          className="mx-auto"
                        />
                      </div>
                    </div>
                  ) : (
                    displayChilds(component)
                  )}
                </div>
                <div
                  className={`${
                    !childs ? "" : childs?.length === 0 ? "hidden" : ""
                  } h-[10%] relative pb-1`}
                >
                  {component === 1 ? (
                    <PaginationComponent
                      pages={Math.ceil(childs?.length! / dataPerPage)}
                      currentPage={currentPage}
                      onPageChange={paginate}
                    />
                  ) : (
                    <PaginationComponent
                      pages={Math.ceil(
                        filteredCourses2?.length! / dataPerPage2
                      )}
                      currentPage={currentPage2}
                      onPageChange={paginate2}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="md:h-[100%] h-[600px] md:col-span-5">
              <div
                className={`${
                  !clientTiersPrestation
                    ? ""
                    : clientTiersPrestation?.length === 0
                    ? "invisible"
                    : "flex items-center justify-between px-2"
                } w-full h-[10%] lg:text-lg md:text-base text-sm`}
              >
                {!clientTiersPrestation ? (
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={"100%"}
                    width="100%"
                  />
                ) : (
                  <div className="w-full flex ">
                    <h2 className="font-extrabold text-dark_blue /md:w-1/2 lg:text-lg md:text-base text-sm w-full">
                      Mes élèves inscrits
                    </h2>
                    <Tooltip
                      title={`Veuillez séléctionner un élève.`}
                      className="font-nunito"
                    >
                      <div className="">
                        <button
                          disabled={!isAction ? true : false}
                          onClick={handleClick}
                          className={`${
                            !isAction
                              ? "border-[#4d4d4d] text-[#4d4d4d] cursor-not-allowed"
                              : "border-first text-first"
                          } border-b-2 px-9 md:py-0 py-2 bg-white font-bold rounded-lg :md:hidden lg:text-lg md:text-base text-sm flex items-center justify-around gap-x-1`}
                        >
                          <span>Action</span>
                          <svg
                            className="w-2.5 h-2.5 ms-2.5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>
                        <Menu
                          id="fade-menu"
                          MenuListProps={{
                            "aria-labelledby": "fade-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          TransitionComponent={Fade}
                        >
                          <MenuItem onClick={handleClose}>
                            {buttonArchivage(dataArchive!)}
                          </MenuItem>
                          <MenuItem onClick={handleClose}>
                            {buttonDelete(dataDelete!)}
                          </MenuItem>
                          <MenuItem onClick={handleClose}>
                            {profileStudent(dataProfile!)}
                          </MenuItem>
                          <MenuItem onClick={handleClose} className="md:hidden">
                            {handleDeclare(dataDeclare!)}
                          </MenuItem>
                        </Menu>
                      </div>
                    </Tooltip>
                  </div>
                  // </div>
                )}
              </div>
              <div className="shadow-dashboard-setup-intro-form bg-white rounded-lg border-[2px] border-first h-[90%]">
                <div className="h-[89%] /overflow-y-auto /scroll">
                  {!clientTiersPrestation ? (
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      height={"100%"}
                      width="100%"
                    />
                  ) : clientTiersPrestation?.length === 0 ? (
                    <div className="text-[#4d4d4d] text-center px-4 mt-10  font-bold">
                      <p className="xl:text-xl lg:text-lg md:text-base text-sm">
                        Aucun de vos élèves n'a encore rempli le formulaire,
                        donc vous ne pouvez donc pas encore déclarer son cours.
                      </p>
                    </div>
                  ) : (
                    // <Tooltip title="Consulter le profil de l'élève" className='font-nunito'></Tooltip>
                    // <CustomPaginationActionsTable/>
                    <table className="text-left w-full relative h-[90%] lg:px-4 /p-3">
                      <thead className="flex /lg:text-lg md:text-base text-sm text-[#4d4d4d] min-h-[10%] w-full border-b-2 border-first">
                        <tr className="flex items-center w-full text-first">
                          <th className="py-2 px-2 md:w-2/6 w-2/5 /hidden /md:block">
                             <div className="flex gap-x-1 items-center">
                              {sort?.sortName ? 
                              <ArrowDownwardIcon className="cursor-pointer" onClick={()=>setSort({sortPrice:false,sortDuration:false,sortName:false,sortPriceDown:false,sortDurationDown:false,sortNameDown:true})}/> : 
                              <ArrowUpwardIcon className="cursor-pointer"  onClick={()=>setSort({sortPrice:false,sortDuration:false,sortName: true,sortPriceDown:false,sortDurationDown:false,sortNameDown:false})}/>}
                              <span className="">Prénom et Nom</span>
                            </div>
                          </th>
                          <th className="py-2 px-2 md:w-1/6 w-1/5">
                            <div className="flex gap-x-1 items-center">
                              {sort?.sortDuration ? 
                              <ArrowDownwardIcon className="cursor-pointer" onClick={()=>setSort({sortPrice:false,sortDuration:false,sortName:false,sortPriceDown:false,sortDurationDown:true,sortNameDown:false})}/> : 
                              <ArrowUpwardIcon className="cursor-pointer"  onClick={()=>setSort({sortPrice:false,sortDuration:true,sortName: false,sortPriceDown:false,sortDurationDown:false,sortNameDown:false})}/>}
                              <span className="">Durée</span>
                              <LightTooltipCours
                                className=" font-nunito"
                                title="Le nombre d'heures dispensées à chacun de vos élèves pour ce mois en cours."
                              >
                                <InfoTwoToneIcon className="cursor-pointer"/>
                              </LightTooltipCours>
                              <span className="md:hidden text-orange">(1)</span>
                            </div>
                          </th>
                          <th className="py-2 px-2 md:w-1/6 w-1/5">
                            <div className="flex items-center gap-x-1">
                            {sort?.sortPrice ? <ArrowDownwardIcon className="cursor-pointer" onClick={()=>setSort({sortPrice:false, sortDuration:false,sortName:false,sortPriceDown:true,sortDurationDown:false,sortNameDown:false})}/> : 
                            <ArrowUpwardIcon className="cursor-pointer" onClick={()=>setSort({sortPrice:true, sortDuration:false,sortName:false,sortPriceDown:false,sortDurationDown:false,sortNameDown:false})}/>}
                              <span className="">Montant</span>
                              <LightTooltipCours
                                className=" font-nunito"
                                title="Il s'agit du montant total facturé pour chacun de vos élèves durant ce mois en cours. Veuillez noter que seulement la moitié de ce montant lui a été/sera prélevé pour les cours de ce mois-ci."
                              >
                                <InfoTwoToneIcon className="cursor-pointer"/>
                              </LightTooltipCours>
                              <span className="md:hidden text-orange">(2)</span>
                            </div>
                          </th>
                          <th className="py-2 px-2 md:w-1/6 w-1/5 pl-4">
                            Statut
                          </th>
                          <th className="py-2 px-2 md:w-1/6 w-1/5 md:pl-4 hidden md:block">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="overflow-y-scroll w-full /scroll /py-2 h-[100%] absolute">
                        <RadioGroup
                          ref={radioGroupRef}
                          // aria-label="ringtone"
                          name="use-radio-group"
                          value={value}
                          onChange={handleChange}
                        >
                          {currentData3
                            ?.filter((child: any) => !child.is_archive)
                            .map((child: any, key: any) => (
                              <AddSutentValid
                                teachr={teachr}
                                child={child}
                                key={key}
                                handleAction={handleAction}
                                onValueChange={handleValueChange}
                                onValueChange2={setIsAction}
                                onSetDataProfile={handleSetDataProfile}
                                onSetDataArchive={handleSetDataArchive}
                                onSetDataDelete={handleSetDataDelete}
                                onSetDataDeclare={handleSetDataDeclare}
                                isAction={isAction}
                                isDelete2={isDelete2}
                                isLoad2={isLoad2}
                                setIsLoad2={setIsLoad2}
                                handleDeleteTrue2={handleDeleteTrue2}
                                handleLoadDelete2={handleLoadDelete2}
                              />
                            ))}
                        </RadioGroup>
                      </tbody>
                    </table>
                  )}
                </div>
                <div
                  className={`${
                    !clientTiersPrestation
                      ? ""
                      : clientTiersPrestation?.length === 0
                      ? "hidden"
                      : "block"
                  } h-[10%]`}
                >
                  <PaginationComponent
                    pages={Math.ceil(filteredCourses3?.length! / dataPerPage3)}
                    currentPage={currentPage3}
                    onPageChange={paginate3}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-y-2 md:hidden mt-3">
                <p className="text-[#424242]">
                  <span className="md:hidden text-orange">(1) </span> Le nombre
                  d'heures de cours dispensées à chacun de vos élèves pour ce
                  mois en cours.
                </p>
                <p className="text-[#424242]">
                  <span className="md:hidden text-orange">(2) </span> Il s'agit
                  du montant total payé pour les heures de cours dispensées à
                  chacun de vos élèves durant ce mois en cours.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export { AddStudentPage, useDisplayAddStudent };
