import React, { useEffect, useRef, useState } from 'react';
import { PunctualCourseDashboard } from '../../../../../../Objects/models/PunctualCourseDashboard';
import { Checkbox, FormControlLabel, Skeleton } from '@mui/material';
import TeachrRepository from '../../../../../../Repositories/TeachrRepository';
import PunctualCourseRepository from '../../../../../../Repositories/PunctualCourseRepository';
import ComponentCourseForAll from './ComponentCourseForAll';
import Teachr from '../../../../../../Objects/models/Teachr';
import moment from 'moment';
import { validatePrice, validateChild, BpCheckedIcon, BpIcon2, LightTooltipCours } from '../../UtilsDashboard/FunctionsDashbord';
import { validateDateTime, validateEndDateTime } from '../../../../../../Validations/ValidationBirthday';
import validateSelects from '../../../../../../Validations/MultiStep';
import StepperStore from '../../../../../../LocalForage/StepperStore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../../../../Services/FirebaseService';
import Swal from "sweetalert2";
import ChildRepository from '../../../../../../Repositories/ChildRepository';
import CustomFeesRepository from '../../../../../../Repositories/CustomFeesRepository';
import info from "../../../../../../Assets/Icons/info-acre.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Switch from "@mui/material/Switch";
interface CourseForAllHandles {
  handleSubmitAllCourse: (cours: any, isAllCourse: boolean) => void;
}
interface Course {
  forChildFirstName?: string;
  forChildLastName?: string;
  id: number;
  teachrPrice?: number;
  endDatetime?: string | Date; // Assurez-vous du format de date
  startDatetime?: string | Date;
  subjectAskedObject?: any;
  duration?: number | string;
  subjectAsked_id?: number;
  address_id?: number;
  forChild_id?: number;
  allCourse?: boolean;
  isNew?: boolean;
  isPassedCourse?: boolean;
}
interface ErrorState {
  [key: string]: {
    child?: string;
    price?: string;
    startDate?: string;
    endDate?: string;
    matiere?: string;
  };
}
const StepCourseForAll = (props: any) => {
  const { handleChangeStep } = props;
  const [user] = useAuthState(auth);
  const data: Course[] = [];
  const dataError: Course[] = [];
  const [test, setTest] = useState<any>();
  const [course2, setCourse2] = useState<any>({});
  const [teachr, setTeachr] = React.useState<Teachr | null>(null);
  const [load, setLoad] = useState(false);
  const [check, setCheck] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [checkedItems, setCheckedItems] = useState<Record<number, { checked: boolean; cours: PunctualCourseDashboard | any }>>({});
  const [errors, setErrors] = useState<ErrorState>({});
  const childRef = useRef<CourseForAllHandles>(null);
  const contentRef = useRef<HTMLDivElement | any>(null);
  const [checked, setChecked] = React.useState(true);
  const [lock, setLock] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const loadTeachrAndCourses = async () => {
    const storedCourses: any = await StepperStore.getItem(`course${user?.uid}`);
    const currentTeachr = await TeachrRepository.getCurrentTeachr();
    const initNextCourse: any = {};
    const initStoredCourse: any = {};
    if (currentTeachr?.id) {
      setTeachr(currentTeachr);
      const nextMonthCourses = await PunctualCourseRepository.getCourseNextMonth(currentTeachr.id);
      nextMonthCourses.forEach((cours: any) => { initNextCourse[cours?.id] = cours });
      if (storedCourses?.course) {
        Object.values(storedCourses?.course)?.forEach((cours: any) => { initStoredCourse[cours?.id] = cours });
        setTest(initStoredCourse)
        setCourse2(initStoredCourse);
      } else {
        setTest(initNextCourse)
        setCourse2(initNextCourse);
      }
    }
  };
  // Update courses and their checked state based on the load variable
  const loadCourses = async () => {
    if (teachr?.id) {
      const nextMonthCourses = await PunctualCourseRepository.getCourseNextMonth(teachr?.id);
      const storedCourses: any = await StepperStore.getItem(`course${user?.uid}`);
      const initialCheckState: Record<number, { checked: boolean; cours: PunctualCourseDashboard }> = {};
      const initNextCourse: any = {}
      const initStoredCourse: any = {}
      if (storedCourses?.course) {
        Object.values(storedCourses?.course)?.forEach((cours: any) => {
          initStoredCourse[cours?.id] = cours;
          initialCheckState[cours?.id] = { checked: false, cours }
        });
        setTest(initStoredCourse);
        setCourse2(initStoredCourse);
        setCheckedItems(initialCheckState);
        setLoad(false);
      } else {
        nextMonthCourses.forEach((cours: any) => {
          initNextCourse[cours?.id] = cours
          initialCheckState[cours?.id] = { checked: false, cours }
        });
        setTest(initNextCourse);
        setCourse2(initNextCourse);
        setCheckedItems(initialCheckState);
        setLoad(false);
      }
    }
  };
  // Handle select all functionality
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const newCheckedItems: Record<number, { checked: boolean; cours: PunctualCourseDashboard }> = {};
    Object.values(course2).forEach((cours: any) => { newCheckedItems[cours?.id] = { checked, cours } });
    setCheckedItems(newCheckedItems);
  };
  const validateCourse = (course: Course, setErrors: React.Dispatch<React.SetStateAction<ErrorState>>): boolean => {
    let isValidPrice = false;
    let isValidEndDate = false;
    let isValidStartDate = false;
    let isValidMatiere = false;
    let isValidChild = false
    const newErrors: ErrorState = {};
    const childValidation = validateChild(course.forChild_id);
    const priceValidation = validatePrice(course.teachrPrice, course.duration);
    const startDateValidation = validateDateTime(course.startDatetime);
    const endDateValidation = validateEndDateTime(course.startDatetime, course.endDatetime);
    const subjectValidation = validateSelects(course.subjectAskedObject);

    if (!childValidation.isValid) {
      newErrors[course?.id] = { ...newErrors[course?.id], child: childValidation.message, };
      isValidChild = true;
    } else {
      newErrors[course?.id] = { ...newErrors[course?.id], child: childValidation.message, };
      isValidChild = false;
    }
    if (!priceValidation.isValid) {
      newErrors[course?.id] = { ...newErrors[course?.id], price: priceValidation.message, };
      isValidPrice = true;
    } else {
      newErrors[course?.id] = { ...newErrors[course?.id], price: priceValidation.message, };
      isValidPrice = false;
    }
    if (!startDateValidation.isValid) {
      newErrors[course?.id] = { ...newErrors[course?.id], startDate: startDateValidation.message, };
      isValidStartDate = true;
    } else {
      newErrors[course?.id] = { ...newErrors[course?.id], startDate: startDateValidation.message, };
      isValidStartDate = false;
    }
    if (!endDateValidation.isValid) {
      newErrors[course?.id] = { ...newErrors[course?.id], endDate: endDateValidation.message, };
      isValidEndDate = true;
    } else {
      newErrors[course?.id] = { ...newErrors[course?.id], endDate: endDateValidation.message, };
      isValidEndDate = false;
    }
    if (!subjectValidation.isValid) {
      newErrors[course?.id] = { ...newErrors[course?.id], matiere: subjectValidation.message, };
      isValidMatiere = true;
    } else {
      newErrors[course?.id] = { ...newErrors[course?.id], matiere: subjectValidation.message, };
      isValidMatiere = false;
    }
    setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));

    if (!isValidPrice && !isValidEndDate && !isValidStartDate && !isValidMatiere && !isValidChild) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (course2) {
      const initialCheckState: Record<number, { checked: boolean; cours: PunctualCourseDashboard }> = {};
      Object.values(course2).forEach((cours: any) => {
        initialCheckState[cours?.id] = { checked: false, cours };
      });
      setCheckedItems(initialCheckState);
    }
  }, [course2]);

  useEffect(() => {
    loadTeachrAndCourses();
  }, []);
  useEffect(() => {
    if (load) {
      scrollToContent()
      loadCourses();
    }
  }, [load, teachr]);
  const handleSubmit = async () => {
    let allValid = false
    const promises = Object.values(checkedItems).map(async (item: { checked: boolean; cours: Course }) => {
      if (item.checked) {
        let customFees;
        let priceValid;
        const course = item.cours;
        if (course?.forChild_id && teachr?.id) {
          const child: any = await ChildRepository.fetchChildById(course?.forChild_id);
          const idClient = parseInt(child?.parent["@id"].substr(9));
          customFees = await CustomFeesRepository.getCustomFeesSingle(idClient, teachr?.id);
          const price: number = course?.teachrPrice ? parseFloat(course.teachrPrice.toString()) : 0;
          const defaultFees: number = teachr?.defaultFees ? parseFloat(teachr.defaultFees) : 0;
          const df: number = 1 - defaultFees;
          priceValid = df !== 0 ? (price / df) / 2 : 0;
        }
        const cours1: Course = {
          teachrPrice: course?.isNew
            ? course.teachrPrice : customFees[0]?.is_teachr_contact
              ? priceValid : course.teachrPrice,
          startDatetime: moment(course.startDatetime).add(1, "M").toDate(),
          endDatetime: moment(course.endDatetime).add(1, "M").toDate(),
          subjectAskedObject: course.subjectAskedObject,
          duration: (course.duration)?.toString(),
          subjectAsked_id: course.subjectAsked_id,
          forChild_id: course.forChild_id,
          id: course.id,
          allCourse: true,
          address_id: course.address_id,
          isPassedCourse: course?.isPassedCourse,
          isNew: course?.isNew,
          forChildFirstName: course?.forChildFirstName,
          forChildLastName: course?.forChildLastName,
        };
        if (validateCourse(cours1, setErrors)) {
          data.push(cours1);
          allValid = true
        } else {
          dataError.push(cours1);
        }
      }
    });
    await Promise.all(promises);
    if (dataError.length === 0 && allValid) {
      if (childRef.current) {
        const text = `Vous êtes sur le point de déclarer les cours pour le mois de ${data.map(
          (child) => child.forChildFirstName + " " + child.forChildLastName
        )}. Veuillez vérifier attentivement toutes les informations relatives aux cours. En cliquant sur "Déclarer", vous procéderez à la validation de cette déclaration.`;
        Swal.fire({
          title: "Déclarer un cours",
          text: text,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0054A3",
          cancelButtonColor: "#4d4d4d",
          confirmButtonText: "Oui, Déclarer !",
          cancelButtonText: "Annuler",
          customClass: {
            actions: "my-actions",
            cancelButton: "order-1 right-gap",
            confirmButton: "order-2",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            setLoad(true);
            data.map((course) => {
              if (childRef.current) {
                childRef.current.handleSubmitAllCourse(course, true);
              }
            });
          } else {
            setLoad(false);
          }
        });
      }
    }
  }
  const scrollToContent = () => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ block: "end" });;
    }
  };
  const handleAddCourse = () => {
    const id = Object.values(course2).length === 0 ? 1 : Math.max(...Object.values(course2).map((cours: any) => cours.id)) + 1;
    const newCourse = new PunctualCourseDashboard(0, "", 0, 0, moment().subtract(1, "M").toDate(), moment().subtract(1, "M").toDate(), false, teachr?.id, false, 3, true, false, "CARD", true, "", "");
    newCourse.subjectAskedObject = { fr_FR: "" };
    newCourse.isPassedCourse = false;
    newCourse.isNew = true;
    newCourse.forChildFirstName = "";
    newCourse.forChildLastName = "";
    newCourse.teachrPrice = null;
    newCourse.id = id
    const updatedCourses = { ...course2, [id]: newCourse }
    const newCheckedItems = { ...checkedItems };
    Object.values(updatedCourses).forEach((cours: any) => { newCheckedItems[cours?.id] = { checked: false, cours } });
    setTest(updatedCourses);
    setCourse2(updatedCourses);
    setCheckedItems(newCheckedItems);
    StepperStore.setItem(`course${user?.uid}`, { course: updatedCourses });
    setLoad(true);
    loadCourses();
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ block: "end" });
    }
  };
  const showActiveCourse = () => {
    return (
      <div className="xl:overflow-hidden overflow-x-auto hover:scroll-$$$$ w-[100%] h-[100%] bg-white lg:shadow-simulator-dashboard rounded-lg list-teacher border-[2px] border-first ">
        {!test ? (
          <div className="overflow-y-auto w-full relative h-[500px]">
            <Skeleton variant="rectangular" width="100%" height="100%" />
          </div>
        ) : Object.values(course2)?.length === 0 ? (
          <div className="md:w-full relative list-teacher h-[500px] mt-6 px-2 py-2 bg-white">
            <div className="mx-auto">
              <div className="md:w-4/5 md:mt-8  mx-auto ">
                <p className="text-[#4d4d4d] text-center lg:text-2xl md:text-lg text-base font-bold mx-auto">
                  Vous avez la possibilité de déclarer simultanément plusieurs cours pour vos élèves, ce qui vous permet d'optimiser la gestion des cours en une seule action.
                </p>
              </div>
              <div className="mx-auto md:mt-12 mt-2 md:w-1/2">
                {/* <img src={ponct_dash} alt="ponct_dash" className="mx-auto" /> */}
              </div>
            </div>
          </div>
        ) : (
          <div className="relative align-middle h-full inline-block min-w-full shadow overflow-y-auto hover:scroll-auto  bg-white shadow-dashboard /md:px-8 /px-4 pt-3 rounded-bl-lg rounded-br-lg">
            <div className="xl:h-[80%]">
              <table className="w-full">
                <thead>
                  <tr className="border-b-2 font-bold border-first ">
                    {/* <th className="px-6 py-3 w-1/6 text-left  text-sm leading-4 text-first tracking-wider">
                      <FormControlLabel
                        label={
                          <p className="font-nunito text-first text-sm font-bold tracking-wider">
                            {" "}
                            Élève{" "}
                          </p>
                        }
                        sx={{ fontFamily: "Nunito" }}
                        className="font-nunito"
                        control={<Checkbox
                          checked={Object.values(course2).length > 0 && Object.values(course2).every((cours: PunctualCourseDashboard | any) => cours?.id !== undefined && checkedItems[cours?.id]?.checked)}
                          className='border-first'
                          onChange={handleSelectAll}
                          checkedIcon={<BpCheckedIcon />}
                          icon={<BpIcon2 />}
                        />}
                      />
                    </th> */}
                    <th className="px-6 py-3 w-1/6 text-left text-sm leading-4 text-first tracking-wider">Élève</th>
                    <th className="px-6 py-3 w-1/6 text-left text-sm leading-4 text-first tracking-wider">Nombre d'heures</th>
                    <th className="px-6 py-3 w-1/6 text-left text-sm leading-4 text-first tracking-wider">Matière</th>
                    <th className="px-6 py-3 w-1/6 text-left leading-4 text-first tracking-wider">Date de début</th>
                    <th className="px-6 py-3 w-1/6 text-left leading-4 text-first tracking-wider">Date de fin</th>
                    <th className="px-6 py-3 w-1/6 text-left leading-4 text-first tracking-wider">Prix horaire payé</th>
                    {/* <th className=" w-1/12 xl:px-2 px-2 py-3 /w-1/6 text-left text-sm leading-4 text-first tracking-wider">
                      <div className="flex items-center">
                        <span >{checked ? 'Prix horaire payé' : 'Rémunération horaire'} </span>
                        <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
                      </div>
                    </th> */}
                    <th className="px-6 py-3 w-1/6 text-left text-sm leading-4 text-first tracking-wider">Action</th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {Object.values(checkedItems)?.map((punctual: any, key) => (
                    <tr id="content" ref={contentRef} key={key} className='group/item hover:bg-slate-100 hover:rounded-xl hover:py-4 group/edit'>
                      <ComponentCourseForAll
                        checkedItems={checkedItems}
                        errors={errors}
                        checked={checked}
                        punctual={punctual}
                        setCheckedItems={setCheckedItems}
                        teachr={teachr}
                        setLoad={setLoad}
                        validateCourse={validateCourse}
                        setErrors={setErrors}
                        course2={course2}
                        data={data}
                        dataError={dataError}
                        check={check}
                        check2={check2}
                        setCheck={setCheck}
                        setCheck2={setCheck2}
                        uid={user?.uid}
                        ref={childRef}
                      />
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* <div className="md:flex justify-between mx-auto md:w-full p-2 border-t-2 border-first">
                <div className="flex justify-center">
                </div>
                <div className="flex justify-center ">
                  <button
                    type="button"
                    onClick={(e) => handleSubmit()}
                    className="inline-block md:px-4 md:py-2 py-2 px-4 border-2 font-extrabold hover:font-extrabold text-sm lg:text-[17px] hover:text-white hover:bg-first    border-first text-first  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24 "
                  >
                    Déclarer
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="overflow-y-auto list-teacher relative h-[100%] mt-4  /bg-white rounded-lg /shadow-dashboard-setup-intro-form">
      <div className="relative h-[100%]">
        <div className="space-y-4 h-[90%] /md:p-10 /p-6 ">
          <div className="flex justify-between items-center">
            <button
              className="border-b-2 border-first text-first lg:text-lg md:text-base text-sm font-bold "
              onClick={() => handleChangeStep(1)}
            >
              <ArrowBackIcon /> Précédent
            </button>
            <button
              // disabled={Object.values(course2).length === 0 ? }
              className="border-2 border-first  text-first p-2 lg:text-lg md:text-base text-sm font-bold rounded-lg"
              onClick={handleAddCourse}
            >
              Nouveau Cours
            </button>
          </div>
          <div className="xl:h-[85%] lg:[77%] h-[85%]   mt-4">
            {showActiveCourse()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepCourseForAll;