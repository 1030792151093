import { PunctualCourseDashboard } from "../../../../../Objects/models/PunctualCourseDashboard";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import ponct_dash from "../../../../../Assets/Images/ponct_dash.svg";
import CompPonctual from "./Component/CompPonctual";
import CompChild from "./Component/CompChild";
import moment from "moment";
import NewChartPayment from "./ChartDashboard/ChartPayment";
import ChartDuration from "./ChartDashboard/ChartDuration";
import { Navigation } from "swiper";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import { Button } from "@mui/material";
import { Skeleton } from "@mui/material";

const renderSlider = () => {
  return (
    <Swiper
      className="h-full"
      slidesPerView={1}
      loop
      speed={700}
      allowTouchMove={false}
      navigation={{
        nextEl: ".next-slide",
      }}
      modules={[Navigation]}
      effect="slide"
    >
      <SwiperSlide>
        <NewChartPayment />
      </SwiperSlide>
      <SwiperSlide>
        <ChartDuration />
      </SwiperSlide>
    </Swiper>
  );
};


const DashboardLayout = (props: any) => {
  const { clientTiersPrest, course } = props
  const navigate = useNavigate();
  const showActiveCourse = () => {
    return (
      <div className="p-4 md:overflow-hidden overflow-x-auto relative w-[100%] lg:h-[53%] h-[50%] rounded-lg  /lg:rounded-[30px]   border-[2px] border-first bg-white shadow-simulator-dashboard" >
        <button className="md:font-extrabold px-2 border-b-2 rounded-lg border-first font-bold text-first lg:text-xl md:text-lg text-sm">
          Mes cours
        </button>
        {!course ? (
          <div className="w-full relative  h-[100%]">
            <Skeleton animation="wave" height="100%" width="100%" />
          </div>
        ) : course.length === 0 ? (
          <div className="  md:w-full relative list-teacher h-[50%] /mt-6 px-2 py-2 bg-white">
            <div className="mx-auto ">
              <div className="px-4 mt-3 mx-auto ">
                <p className="text-[#4d4d4d] lg:text-xl text-center md:text-lg text-base font-bold mx-auto">
                  Afin de recevoir un paiement via notre plateforme, veuillez
                  déclarer votre premier cours, ainsi que tout autre cours que
                  vous avez déjà dispensé.
                </p>
              </div>
              <div className="mx-auto /md:mt-12 mt-2 md:w-1/2">
                <img src={ponct_dash} alt="ponct_dash" className="mx-auto" />
              </div>
            </div>
          </div>
        ) : (
          <div className="h-[100%] w-[100%] mt-2">
            <table className="min-w-full /lg:text-lg md:text-base text-sm">
              <thead>
                <tr className="border-b-2 border-first">
                  <th className="/px-6 w-2/6 py-2 text-left leading-4 text-first tracking-wider">
                    Période de cours
                  </th>
                  <th className="/px-6 w-1/6 py-2 text-left text-sm leading-4 text-first tracking-wider">
                    Élève
                  </th>
                  <th className="/px-6 w-1/6 py-2 text-left text-sm leading-4 text-first tracking-wider">
                    Matière
                  </th>
                  <th className="/px-6 w-1/6 py-2 text-left text-sm leading-4 text-first tracking-wider">
                    Durée
                  </th>
                  <th className="/px-6 w-1/6 py-2 text-left text-sm leading-4 text-first tracking-wider">
                    Statut
                  </th>
                </tr>
              </thead>
              <tbody className="md:overflow-y-scroll scroll h-[80%]">
                {course
                  ?.sort(function (a: any, b: any) {
                    const date1: any = moment(a.startDatetime).toDate();
                    const date2: any = moment(b.startDatetime).toDate();
                    return date1 - date2;
                  })
                  ?.slice(0, 4)
                  ?.map((punctual: PunctualCourseDashboard, key: any) => (
                    <CompPonctual punctual={punctual} key={key} />
                  ))}
              </tbody>
            </table>
            <div
              className="text-first flex md:right-6 right-20 bottom-2 absolute cursor-pointer gap-x-2"
              onClick={() => navigate("/teachr/dashboard/coursetype")}
            >
              <strong className="underline">voir plus</strong>
              <AddCircleTwoToneIcon />
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <div className="md:h-[90%] lg:overflow-hidden overflow-auto h-[95%] lg  grid lg:grid-cols-5 gap-4 grid-cols-1">
      <div className=" h-[100%] md:col-span-3 /">
        <div className="rounded-lg border-[2px] border-first shadow-simulator-dashboard mb-5 /h-[80%] bg-white lg:hidden pt-2">
          {renderSlider()}
        </div>
        <div className="h-[40%] bg-white mb-4 border-[2px] bg-whiterounded-[17px] relative /lg:rounded-[30px] rounded-lg lg:border-[2px] border-first lg:shadow-simulator-dashboard p-4 -pt-2 lg:block hidden">
          <div className="lg:block">
            <button className="md:font-extrabold px-2 border-b-2 rounded-lg border-first font-bold text-first lg:text-xl md:text-lg text-sm">Mes élèves</button>
          </div>
          {clientTiersPrest?.length === 0 ? (
            <div className="">
              <div className="text-[#4d4d4d] text-center xl:text-xl lg:text-lg text-xl px-4 md:mt-10 font-bold">
                <p
                  onClick={() => navigate("/teachr/dashboard/setup")}
                  className="text-first cursor-pointer lg:translate-y-4"
                >
                  Actuellement, aucun élève est en attente de vérification. Si
                  vous souhaitez inscrire un nouvel élève, vous êtes invité(e) à
                  cliquer sur l'option{" "}
                  <strong className="text-first">Ajouter un élève</strong>.
                </p>
              </div>
            </div>
          ) : (
            <table className="text-left w-full relative h-[78%] mt-2 lg:px-4 /p-3">
              <thead className="flex/lg:text-lg md:text-base text-sm text-[#4d4d4d] h-[10%] w-full border-b-2 border-first">
                <tr className="flex w-full text-first">
                  <th className="md:py-2 md:w-2/5 w-2/4">Prénom et Nom</th>
                  <th className="py-2 md:w-1/5 w-1/4 md:px-2">Date d'ajout</th>
                  <th className="py-2 md:w-2/5 w-1/4 md:px-2">Statut</th>
                </tr>
              </thead>
              <tbody className="overflow-hidden w-full h-[100%] absolute">
                {clientTiersPrest
                  ?.sort(function (a: any, b: any) {
                    const date1: any = moment(a.createdAt.date).toDate();
                    const date2: any = moment(b.createdAt.date).toDate();
                    return date2 - date1;
                  })
                  ?.slice(0, 2)
                  ?.map((child: any, key: any) => (
                    <CompChild child={child} key={key} />
                  ))}
              </tbody>
            </table>
          )}
          <div
            className="text-first mx-auto flex right-6 absolute cursor-pointer gap-x-2"
            onClick={() => navigate("/teachr/dashboard/student")}
          >
            <strong className="underline">voir plus</strong>
            <AddCircleTwoToneIcon />
          </div>
        </div>
        {showActiveCourse()}
      </div>
      <div className="bg-white md:col-span-2  lg:flex hidden relative /rounded-[17px] /lg:rounded-[30px] rounded-lg lg:border-[2px] border-first lg:shadow-simulator-dashboard items-center gap-x- justify-between h-[96%]">
        {renderSlider()}
        {/* <Button
          id="change-chart"
          className={`animate__animated w-10 h-10 /p-2 text-center rounded-full bg-first next-slide disabled:opacity-40`}
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 2L12.5618 11.1216C12.7928 11.321 12.7928 11.679 12.5618 11.8784L2 21"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
            />
          </svg>
        </Button> */}
      </div>
    </div>
  );
};

export { DashboardLayout, renderSlider };
